/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "pgalAdminApi",
            "endpoint": "https://d6r1qd6nja.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "pgalApi",
            "endpoint": "https://midvebn9cb.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1cbd8c8b-3658-49a1-9c92-ff2561ef1176",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_v7XMwXB57",
    "aws_user_pools_web_client_id": "dracs5rc5u1ghb6hbl7u16cq9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "pgal-schedule-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "pgal-announcements-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "pgal-seasons-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "pgal-teams-staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
