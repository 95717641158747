import PGALTable from "../common/PGALTable";

const PlayoffLeaderboard = ({ headers, rows }) => {
    return (
        <PGALTable
            color="tertiary"
            headers={headers}
            rows={rows}
        />
    );
};

export default PlayoffLeaderboard;