import React from 'react';

import { Box, Grid, Typography, alpha } from "@mui/material";
import { styled } from '@mui/system';
import ProxBox from '../ProxBox';
import { convertHandicapText } from '../../utils/teamUtils';

const TeamBox = styled(Box)(({ theme, result, playoff = false }) => {
    let backgroundColor = 'none';
    if (playoff) {
        return backgroundColor;
    } else if (result === 'W') {
        backgroundColor = alpha(theme.palette.success.light, .5);
    } else if (result === 'T') {
        backgroundColor = alpha(theme.palette.tertiaryGray.light, .15);
    }
    return {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor,
    };
});

const TeamName = styled(Typography)(() => ({
    fontSize: '.95em',
}));

const Handicap = styled(Typography)(({ theme, handicap }) => {
    let color = theme.palette.tertiaryGray.main;
    if (handicap > 0) {
        color = theme.palette.tertiary.light;
    }
    if (handicap < 0) {
        color = theme.palette.secondary.light;
    }
    return ({
        color,
        paddingLeft: theme.spacing(1),
        fontWeight: 'bold',
    })
});

const convertScoreText = (team) => {
    if (team?.score) {
        if (!team?.adjustedScore) {
            return team.score;
        }

        return `${Number(team?.score)}/${Number(team?.adjustedScore)}`
    }
    return '';
}

const TeamItem = ({ team, playoff, forfeitMatchup }) => {
    if (!team?.players) {
        return null;
    }
    
    const handicap = team?.handicap;

    return (
        <TeamBox
            result={team?.result}
            playoff={playoff}
        >
            <Grid
                container
                display="flex"
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    item
                    xs={10}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={8}>
                            <Box sx={{ paddingLeft: '8px' }}>
                                <TeamName>{team?.players[0]?.name}</TeamName>
                                <TeamName>{team?.players[1]?.name}</TeamName>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                { team.forfeit && (
                                    <Typography sx={{ padding: '0px 2px 0px 2px', fontWeight: 'bold', color: 'tertiary.contrastText', backgroundColor: 'tertiary.main' }} >
                                        FORFEIT
                                    </Typography>
                                )}
                                { team?.score && !team.forfeit && (
                                    <Typography sx={{ marginRight: '2px' }} >
                                        { convertScoreText(team, forfeitMatchup) }
                                    </Typography>
                                )}
                                { !team.forfeit && (
                                    <Handicap
                                        handicap={handicap}
                                    >
                                        ({ convertHandicapText(handicap, team.ringer) })
                                    </Handicap>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: '8px'
                    }}
                >
                    <Typography>{ team.record }</Typography>
                </Grid>
            </Grid>
        </TeamBox>
    )
};

const Matchup = styled('div')(({ theme, lastNdx }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: lastNdx ? null : `1px dotted ${alpha(theme.palette.tertiaryGray.main, .5)}`,
}))

const StyledLineBreak = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1)
}))

const MatchupPreview = ({ matchup, playoff, lastNdx, week, forfeitMatchup}) => (
    <Matchup
        lastNdx={lastNdx}
    >
        <Grid 
            container 
            spacing={0}
            alignItems="center"
        >
            <Grid
                item 
                xs={10}
                sx={{
                    borderRight: `1px solid black`,
                }}
            >
                <Box
                    sx={{
                        flexDirection: 'column',
                        paddingRight: '8px'
                    }}
                >
                    <TeamItem team={matchup.scores[0]} week={week} playoff={playoff} forfeitMatchup={forfeitMatchup} />
                    <StyledLineBreak />
                    <TeamItem team={matchup.scores[1]} week={week} playoff={playoff} forfeitMatchup={forfeitMatchup}/>
                </Box>
            </Grid>
            <Grid
                item 
                xs={2}
                sx={{
                    height: '100%',
                    paddingLeft: '8px'
                }}
            >
                <Typography>{ matchup.time }</Typography>
            </Grid>
        </Grid>
    </Matchup>
);

const LeftLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
}));

const SchedulePreview = ({ match }) => {
    if (!match) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '8px'
                }}
            >
                There are no upcoming matches
            </Box>
        )
    }

    let week;
    if (match?.bye) {
        week = 'BYE';
    } else if (match?.playOff) {
        week = 'PLAYOFFS';
    } else {
        week = match.week;
    }

    return (
        <>
            <Box>
                <Grid 
                    container
                    spacing={0}
                    sx={{
                        padding: 1,
                    }}
                >
                    <Grid item xs={3}><LeftLabel>WEEK:</LeftLabel></Grid>
                    <Grid item xs={9}>{ week }</Grid>
                    <Grid item xs={3}><LeftLabel>DATE:</LeftLabel></Grid>
                    <Grid item xs={9}>{ match.date }</Grid>
                    <Grid item xs={3}><LeftLabel>COURSE:</LeftLabel></Grid>
                    <Grid item xs={9}>{ match.bye ? 'N/A' : match.holes }</Grid>
                </Grid>
                {
                    !match.bye && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            { match.prox.map(({ id, hole, type, winner }, index) => (
                                <Box sx={{ width: '49%' }} key={`prox-${id}-${index}`}>
                                    <ProxBox type={type} hole={hole} winner={winner} />
                                </Box>
                            ))}
                        </Box>
                    )
                }
            </Box>
            <Box>
                {
                    match.matchups.map((matchup, ndx) => (
                        <MatchupPreview
                            matchup={matchup}
                            key={matchup.id}
                            lastNdx={match.matchups.length-1 === ndx}
                            week={match.week}
                            playoff={match.playOff}
                            forfeitMatchup={matchup.scores[0]?.forfeit || matchup.scores[1]?.forfeit}
                        />
                    ))
                }
            </Box>
            <Typography sx={{ marginTop: '16px' }} variant="subtitle2">* represents a handicap due to a substitute player</Typography>
        </>
    )
}

export default SchedulePreview;