import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import moment from 'moment';
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { FORM_MODE } from "../../../../utils/enums";
import PGALMarkdown from "../../../PGALMarkdown";

const ManageAnnouncement = ({
    form,
    formMode,
    onFormChange,
    disabled,
    selectedTab,
    onSelectedTabChange,
    onEdit,
    onSave,
    onCancel,
    onDelete,
}) => (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{form.title}</Typography>
        </AccordionSummary>
        <AccordionDetails
            sx={{ backgroundColor: 'white' }}
        >
            <Stack component="form" noValidate spacing={3} sx={{ width: '100%' }}>
                <TextField
                    id="title"
                    label="title"
                    type="text"
                    value={form?.title}
                    onChange={({ target: { value } }) => {
                        onFormChange('title', value)
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                />
                <TextField
                    id="date"
                    label="Date"
                    type="date"
                    value={moment(form.date).format("yyyy-MM-DD")}
                    onChange={({ target: { value } }) => {
                        onFormChange('date', moment(value).format("yyyy/MM/DD"))
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={form.expanded}
                            onChange={({ target: { checked }}) => {
                                onFormChange('expanded', checked)
                            }}
                            disabled={disabled}
                        />
                    }
                    label="Expanded?"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={form.active}
                            onChange={({ target: { checked }}) => {
                                onFormChange('active', checked)
                            }}
                            disabled={disabled}
                        />
                    }
                    label="Active?"
                />
                <ReactMde
                    value={form?.content || ''}
                    onChange={(value) => onFormChange('content', value)}
                    selectedTab={selectedTab}
                    onTabChange={onSelectedTabChange}
                    generateMarkdownPreview={(markdown) => Promise.resolve(<PGALMarkdown content={markdown} />)}
                    childProps={{
                        writeButton: {
                            tabIndex: -1
                        }
                    }}
                    readOnly={disabled}
                />
            </Stack>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '16px',
                }}
            >
                {
                    formMode !== FORM_MODE.READ
                        ? (
                            <>
                                <Button sx={{ marginRight: '16px' }} size="large" onClick={onSave} color="tertiary" variant="contained">Save</Button>
                                <Button sx={{ marginLeft: '16px' }} size="large" onClick={onCancel} color="primary">Cancel</Button>
                            </>
                        ) : (
                            <>
                            <Button sx={{ marginRight: '16px' }} size="large" onClick={onEdit} color="warning" variant="contained">Edit</Button>
                            <Button sx={{ marginLeft: '16px' }} size="large" onClick={onDelete} color="error">Delete</Button>
                            </>
                        )
                }
            </Box>
        </AccordionDetails>
    </Accordion>
);

export default ManageAnnouncement;