import React, { useContext } from "react";

export const AppContext = React.createContext({
    teams: [],
    matches: [],
    players: [],
});

const useAppContext = () => {
    const context = useContext(AppContext);
    return context;
}

export default useAppContext;

