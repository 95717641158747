import { useCallback } from "react";
import { useEffect, useMemo, useState } from "react";
import { fetchHandicapMetadata, fetchPreview, updateSeason } from "../../../api/pgalAdminApis";
import Loader from "../../common/Loader";
import ManageSeason from "./ManageSeason";

const convertToValueNamePair = obj => Object.keys(obj).map(key => ({ value: key, text: obj[key].name }));

const DEFAULT_FORM = {
    maxHandicap: undefined,
    cappedHandicap: false,
    ringerStrategy: '',
    roundStrategy: '',
    algorithm: '',
}

const validateForm = ({
    maxHandicap,
    ringerStrategy,
    roundStrategy,
    algorithm
}) => maxHandicap 
&& ringerStrategy
&& roundStrategy
&& algorithm;

const ManageSeasonContainer = () => {
    const [selectedSeason, setSelectedSeason] = useState();
    const [handicapMetadata, setHandicapMetadata] = useState();
    const [season, setSeason] = useState();
    const [form, setForm] = useState(DEFAULT_FORM);
    const [loading, setLoading] = useState('');

    useEffect(
        () => {
            const fetchData = async () => {
                    setLoading('Loading season...')
                    const data = await fetchPreview(selectedSeason, {});
                    const { handicapMeta = {} } = data;
                    setForm({
                        maxHandicap: handicapMeta.maxHandicap,
                        cappedHandicap: handicapMeta.capHandicapCalc,
                        ringerStrategy: handicapMeta.ringerStrategy,
                        roundStrategy: handicapMeta.handicapRoundStrategy,
                        algorithm: handicapMeta.handicapAlgorithm,
                    })
                    setLoading(undefined)
            }
            if(selectedSeason) {
                fetchData();
            }
        },
        [selectedSeason,setLoading,setForm]
    );

    useEffect(
        () => {
            const fetchData = async () => {
                const data = await fetchHandicapMetadata();
                setHandicapMetadata(data);
            }
            fetchData();
        },
        [setHandicapMetadata]
    );

    const onFormChange = useCallback(
        (field, value) => {
            setForm({
                ...form,
                [field]: value
            });
            // if you change the the form, reset the preview
            setSeason(undefined);
        },
        [form, setForm]
    )

    const metadataOptions = useMemo(
        () => {
         if (handicapMetadata) {
            return {
                roundStrategy: convertToValueNamePair(handicapMetadata.roundStrategy),
                ringerStrategy: convertToValueNamePair(handicapMetadata.ringerStrategy),
                algorithm: convertToValueNamePair(handicapMetadata.algorithm),
            }
         }       
         return undefined;
        },
        [handicapMetadata]
    );

    const onPreview = useCallback(
        () => {
            const fetchData = async () => {
                setLoading('Generating Season Preview...')
                const req = {
                    capHandicapCalc: form.cappedHandicap,
                    handicapAlgorithm: form.algorithm,
                    handicapRoundStrategy: form.roundStrategy,
                    maxHandicap: form.maxHandicap,
                    ringerStrategy: form.ringerStrategy
                }
                const data = await fetchPreview(selectedSeason, req);
                setSeason(data);
                setLoading(undefined);
            }
            fetchData();
        },
        [form, selectedSeason, setSeason, setLoading]
    );

    const onSave = useCallback(() => {
        const saveData = async () => {
            setLoading('Saving Season Metadata...')
            const req = {
                capHandicapCalc: form.cappedHandicap,
                handicapAlgorithm: form.algorithm,
                handicapRoundStrategy: form.roundStrategy,
                maxHandicap: form.maxHandicap,
                ringerStrategy: form.ringerStrategy
            }
            const data = await updateSeason(selectedSeason, req);
            setSeason(data);
            setLoading(undefined);
        }
        saveData();
    }, [form, selectedSeason])

    return (
        <>
            <Loader
                text={loading}
                active={Boolean(loading)}
            />
            <ManageSeason
                selectedSeason={selectedSeason}
                onSelectSeason={setSelectedSeason}
                metadataOptions={metadataOptions}
                handicapMetadata={handicapMetadata}
                form={form}
                onFormChange={onFormChange}
                onPreview={validateForm(form) && onPreview}
                season={season}
                onSave={season && onSave}
            />
        </>
    );
};

export default ManageSeasonContainer;