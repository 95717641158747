import CalendarViewMonth from '@mui/icons-material/CalendarViewMonth';
import CampaignIcon from '@mui/icons-material/Campaign';
import EventNoteIcon from '@mui/icons-material/EventNote';
import GroupIcon from '@mui/icons-material/Group';
import HistoryIcon from '@mui/icons-material/History';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SportsGolfIcon from '@mui/icons-material/SportsGolf';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Amplify } from "aws-amplify";
import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppHeader from "../AppHeader";
import Home from "../Home";
import LeagueInfo from "../LeagueInfo";
import AppRouter from "../Router/AppRouter";
import Schedule from "../Schedule";
import Teams from "../Teams";
import './App.css';
import { getPgalTheme } from './appTheme';
import Body from "./Body";

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "../../api/httpAction";
import { fetchSeasons as fetchSeasonsApi } from "../../api/pgalApis";
import { AppContext } from "../../hooks/useAppContext/useAppContext";
import ManageAnnouncements from "../Admin/ManageAnnouncements";
import ManageMatchups from "../Admin/ManageMatchups";
import ManageSeason from "../Admin/ManageSeason";
import AppFooter from "../AppFooter";
import PreviousSeasons from "../PreviousSeasons";

import awsExports from "../../aws-exports";
import ScheduleGenerator from '../Admin/ScheduleGenerator';
import { fetchHandicapMetadata } from '../../api/pgalAdminApis';
Amplify.configure(awsExports);

const PAGES = [{
    title: 'Home',
    path: '/',
    component: Home,
    Icon: HomeIcon,
}, {
    title: 'League Info',
    path: '/info',
    component: LeagueInfo,
    Icon: SportsGolfIcon,
}, {
    title: 'Schedule',
    path: '/schedule',
    component: Schedule,
    Icon: CalendarViewMonth,
}, {
    title: 'Teams',
    path: '/teams',
    component: Teams,
    Icon: GroupIcon,
}, {
    title: 'Previous Seasons',
    path: '/seasons',
    component: PreviousSeasons,
    Icon: HistoryIcon,
}];

const ADMIN_PAGES = [{
    admin: true,
    title: 'Manage Announcements',
    path: '/admin/announcments',
    component: ManageAnnouncements,
    Icon: CampaignIcon,
}, {
    admin: true,
    title: 'Manage Matchups',
    path: '/admin/matchups',
    component: ManageMatchups,
    Icon: EventNoteIcon,
}, {
    admin: true,
    title: 'Manage Season',
    path: '/admin/seasons',
    component: ManageSeason,
    Icon: MenuBookIcon,
}, {
    admin: true,
    title: 'Schedule Generator',
    path: '/admin/schedule-generator',
    component: ScheduleGenerator,
    Icon: MenuBookIcon,
}];

const ALL_PAGES = [
    ...PAGES,
    ...ADMIN_PAGES,
];

const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}

const enrichScoresWithPlayers = (season, matchup) => {
    let scores = matchup.scores;
    if(!scores || matchup.scores.length == 0) {
        scores = (matchup?.teams || []).map(({ teamId }) => ({ teamId }));
    }


    return (scores || []).map(score => ({
        ...score,
        players: {
            ...season.teams.find(({ id }) => id === score.teamId)?.players,
        }
    }));
}

const enrichMatchesWithTeams = season => {
    const matches = {
        ...season,
        weeks: season.weeks.map(week => ({
          ...week,
          matchups: week.matchups.map(matchup => ({
            ...matchup,
            scores: enrichScoresWithPlayers(season, matchup),
          }))
        })),
    };

    return matches;
}

/**
 * Finds the next upcoming match, assuming that the list
 * of matches is sorted
 * @param {*} weeks 
 * @returns 
 */
const findUpcomingMatch = weeks => {
    const today = new Date();
    today.setHours(0,0,0,0);
    return (weeks || []).filter(({ date }) => today <= new Date(date))[0];
}

const getMinutes = time => {
    const timeArr = time.split(':');
    return (Number(timeArr[0])*60) + Number(timeArr[1]);
}

const App = () => {
    const [theme, setTheme] = useState('masters')
    const [seasons, setSeasons] = useState([]);
    const [season, setSeason] = useState({});
    const [players, setPlayers] = useState([]);
    const [upcomingMatch, setUpcomingMatch] = useState();
    const [handicapMetadata, setHandicapMetadata] = useState();

    const fetchCurrentSeason = useCallback(async () => {
        const data = await get("/seasons/current");
        data.weeks.sort((a,b) => a.week - b.week)
        data.weeks.forEach(week => {
            week.matchups.sort((a, b) => getMinutes(a.time) - getMinutes(b.time))
        });
        setSeason(enrichMatchesWithTeams(data));
        setUpcomingMatch(findUpcomingMatch(data.weeks))
    }, [setSeason, setUpcomingMatch]);

    useEffect(
        () => {
            const userTheme = localStorage.getItem('theme');
            if (userTheme) {
                if (userTheme === 'light') {
                    setTheme('masters')
                }
                setTheme(userTheme);
            }
        },
        []
    );

    const themeChange = useCallback(
        (theme) => {
            setTheme(theme);
            localStorage.setItem('theme', theme)
        },
        [setTheme]
    );



    useEffect(() => {
        const fetchTeams = async () => {
            const apiData = await get("/teams");
            const playerData = apiData.reduce((prev, curr) => ([
                ...prev,
                ...Object.values(curr.players)
            ]), []);
            setPlayers(playerData);
        }

        const fetchSeasons = async () => {
            const data = await fetchSeasonsApi();
            setSeasons(
                (data || []).map(enrichMatchesWithTeams)
            );
        }

        const fetchMetadata = async () => {
            const data = await fetchHandicapMetadata();
            setHandicapMetadata(data);
        }

        fetchMetadata();
        fetchTeams();
        fetchCurrentSeason();
        fetchSeasons();
    }, []);

    const pgalTheme = getPgalTheme(theme)

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <AppContext.Provider
                value={{
                    theme: {
                        theme,
                        setTheme: themeChange,
                    },
                    seasons: seasons,
                    season: season,
                    teams: season.teams,
                    playoffBoard: season.playoffBoard,
                    players,
                    matches: season.weeks,
                    upcomingMatch: upcomingMatch,
                    handicapMetadata: handicapMetadata,
                    fetchMatches: fetchCurrentSeason,
                }}
            >
                <ThemeProvider theme={pgalTheme}>
                    <CssBaseline />
                    <Router>
                        <ScrollToTop />
                        <AppHeader pages={ALL_PAGES} />
                        <Body>
                            <AppRouter
                                pages={ALL_PAGES}
                            />
                            <AppFooter />
                        </Body>
                    </Router>
                </ThemeProvider>
            </AppContext.Provider>
        </LocalizationProvider>
    );
}

export default App;
