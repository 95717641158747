import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminHome from "../Admin/AdminHome";
import useUser from '../../hooks/useUser'

const AppRouter = ({ pages }) => {

    const { user } = useUser();

    return (
        <Switch>
            { pages.filter(({ admin }) => !admin || (admin && user)).map(({ path, component: Component }) => (
                <Route
                    key={path}
                    exact
                    path={path}
                >
                    <Component />
                </Route>
            ))}
            <Route
                exact
                path="/admin"
            >
                <AdminHome />
            </Route>
        </Switch>
    );
}

export default AppRouter;