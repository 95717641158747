import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import PGALDivider from "../../../common/PGALDivider";
import Panel from "../../../Panel";
import TeamHandicap from "../TeamHandicap";
import TeamScores from "../TeamScores";

const TeamSeasonStats = ({
    season,
    seasonOption,
    onSeasonOptionChange,
    seasonOptions
}) => (
    <Panel
        title="Team Season Stats"
    >
        <br />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: "center"
                }}
            >
                <ToggleButtonGroup
                    size="small"
                    color="secondary"
                    exclusive
                    value={seasonOption}
                    onChange={({ target: { value }}) => onSeasonOptionChange(value)}
                >
                    { 
                        seasonOptions.map(option => (
                            <ToggleButton
                                value={ option }
                            >
                                { option }
                            </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
        { season && (
            <>
                <br />
                <PGALDivider
                    text="Season Scores"
                />
                <br />
                <TeamScores
                    season={season}
                />
                <br />
                <PGALDivider
                    text="Handicap Summary"
                />
                <br />
                <TeamHandicap
                    season={season}
                />
            </>
        )}
    </Panel>
);

export default TeamSeasonStats;