import { addWeeksToDate } from "../../../utils/date.utils";
import { addMinutesToTime } from "../../../utils/time.utils";

/**
 * @typedef { Object } Prox
 * 
 * @param { number } hole 
 * @param { string } type
 * @param { string } winner
 */

/**
 * @typedef { Object } TeamMatchup
 * 
 * @param { string } teamId 
 * @param { boolean } ringer
 * @param { bolean } score
 */

/**
 * @typedef { Object } Matchup
 * 
 * @param { string } id 
 * @param { string } time
 * @param { TeamMatchup[] } time
 */

/**
 * @typedef { Object } LeagueWeek
 * 
 * @param { string } id
 * @param { number } season 
 * @param { number } week 
 * @param { string } date
 * @param { string } holes
 * @param { boolean } playoff
 * @param { Prox[] } prox
 * @param { Matchup[] } matchups
 */

const buildMatchups = (matchups, startTime) => {
    return matchups.map((matchup, ndx) => ({
        id: matchup.id,
        time: addMinutesToTime(startTime, 8*ndx),
        teams: matchup.teams.map(({ id }) => ({ teamId: id }))
    }));
}

const buildLeagueWeek = (year, week, date, startTee) => {
    return {
        id: `s${week.week}-${year}`,
        date,
        holes: "FRONT",
        playOff: false,
        season: year,
        week: week.week,
        prox: [
            { hole: 1, type: "CLOSEST_APPROACH" },
            { hole: 1, type: "CLOSEST_APPROACH" },
        ],
        matchups: buildMatchups(week.matchups, startTee),
    }
}

/**
 * 
 */
export const enrichSchedule = (schedule, { startDate, byeWeeks = [], startTee }, season) => {
    schedule.sort((a, b) => a.week - b.week);
    const enrichedByeWeeks = byeWeeks.map(date => new Date(date).toLocaleDateString())
    let dateObj = addWeeksToDate(new Date(startDate), -1);
    return schedule.map((week, ndx) => {
        dateObj = addWeeksToDate(dateObj, 1);
        while(enrichedByeWeeks.includes(dateObj.toLocaleDateString())) {
            dateObj = addWeeksToDate(dateObj, 1);
        }
        return buildLeagueWeek(season, week, dateObj.toLocaleDateString(), startTee)
    });
}