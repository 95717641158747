import useAppContext from "../../hooks/useAppContext";
import LeagueInfo from "./LeagueInfo";

const LeagueInfoContainer = () => {
    const { season, handicapMetadata } = useAppContext();

    return (
        <LeagueInfo
            season={season}
            handicapMetadata={handicapMetadata}
        />
    )
};

export default LeagueInfoContainer;