import { Box, Typography, CircularProgress } from "@mui/material";

const InlineLoader = ({
    text,
    loading,
    children
}) => {
    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <Typography>
                    { text }
                </Typography>
                <CircularProgress color="inherit" />
            </Box>
        );
    }

    return children;
};

export default InlineLoader;