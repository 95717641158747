import { API } from "aws-amplify";

const ADMIN_API_NAME = "pgalAdminApi";
const API_NAME = "pgalApi";

const generateParams = (params = {}) => ({
    queryStringParameters: params,
});

const handleResponse = (response) => response?.data;

export const getCall = async (apiName, endpoint, params = {}) => API.get(apiName, endpoint, { ...generateParams(params) }).then(handleResponse);
const postCall = (apiName, endpoint, body = {}) => API.post(apiName, endpoint, { body }).then(handleResponse);
const putCall = (apiName, endpoint, body = {}) => API.put(apiName, endpoint, { body }).then(handleResponse);
const deleteCall = (apiName, endpoint) => API.delete(apiName, endpoint).then(handleResponse);

export const get = async (endpoint, params = {}) => await getCall(API_NAME,endpoint, params);
export const getAdmin = async endpoint => await getCall(ADMIN_API_NAME,endpoint);

export const post = async (endpoint, req) => await postCall(API_NAME,endpoint,req);
export const postAdmin = async (endpoint, req) => await postCall(ADMIN_API_NAME,endpoint,req);

export const put = async (endpoint, req) => await putCall(API_NAME,endpoint,req);
export const putAdmin = async (endpoint, req) => await putCall(ADMIN_API_NAME,endpoint,req);

export const del = async endpoint => await deleteCall(API_NAME,endpoint);
export const delAdmin = async endpoint => await deleteCall(ADMIN_API_NAME,endpoint);