import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/system";

const CustomMenuItem = styled(MenuItem)(({ size }) => ({
    fontSize: `${size}!important`,
}));

const CustomForm = styled(FormControl)(({ size }) => ({
    fontSize: `${size}!important`,
}));

const PGALDropdown = ({
    id,
    label,
    value,
    onChange,
    menuItems,
    fullWidth,
    size = 'medium'
}) => (
    <CustomForm fullWidth={fullWidth}>
        <InputLabel id={`label-${id}`}>{label}</InputLabel>
        <Select
            labelId={`label-${id}`}
            id={`dropdown-${id}`}
            label={label}
            value={value || ''}
            onChange={({ target: { value }}) => {
                onChange(value)
            }}
        >
            {
                (menuItems || []).map(({ value, text, disabled = false }) => <CustomMenuItem disabled={disabled} size={size} value={value}>{text}</CustomMenuItem>)
            }
        </Select>
    </CustomForm>
);

export default PGALDropdown;