import { useEffect, useState, useMemo } from "react";
import { get } from "../../api/httpAction";
import Panel from "../Panel/Panel";
import SideEffect from "../SideEffect";
import Announcement from "./Announcement";

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchAnnouncements = async () => {
        setIsLoading(true);
        const announcements = await get("/announcements");
        setAnnouncements(announcements);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchAnnouncements();
    }, []);

    const filteredAnnouncements = useMemo(
        () => (announcements || []).filter(({ active }) => active),
        [announcements]
    );
    
    return (
        <Panel
            title="Announcements"
        >
            {
                isLoading
                    ? <SideEffect loading={isLoading} loadingMessage="...Loading Announcements" />                    
                    : filteredAnnouncements.map(({ title, date, content, expanded }, ndx) => (
                        <Announcement
                            key={ndx}
                            title={title}
                            date={date}
                            content={content}
                            defaultExpanded={expanded}
                        />
                ))
            }
        </Panel>
    );
};

export default Announcements;