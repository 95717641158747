import { Backdrop } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { del, get, post, put } from "../../../api/httpAction";
import SideEffect from "../../SideEffect";
import ManageAnnouncements from "./ManageAnnouncements";



const ManageAnnouncementsContainer = () => {
    const [, setAnnouncements] = useState([]);
    const [announcementsLocal, setAnnouncementsLocal] = useState([]);
    const [, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const fetchAnnouncements = useCallback(
        async () => {
            setIsLoading(true);
            const announcements = await get("/announcements");
            setAnnouncements(announcements);
            setAnnouncementsLocal(announcements);
            setIsLoading(false);
        },
        [setAnnouncements, setAnnouncementsLocal, setIsLoading]
    )

    const onSave = useCallback(async (id, form) => {
        setIsSaving(true);
        if (id) {
            await put(`/admin/announcements/${id}`, form)
        } else {
            const nextId = Math.max(...(announcementsLocal || []).filter(({ id }) => Boolean(id)).map(({ id }) => id.substring(1))) + 1;
            await post('/admin/announcements', {
                ...form,
                id: `a${nextId}`,
            });
        }
        setIsSaving(false);
        fetchAnnouncements();
    }, [setIsSaving, fetchAnnouncements, announcementsLocal]);

    const onDelete = useCallback(async (id) => {
        setIsSaving(true);
        if(id) {
            await del(`/admin/announcements/${id}`)
            fetchAnnouncements();
        }
        setIsSaving(false);
        fetchAnnouncements();
    }, [fetchAnnouncements]);

    useEffect(() => {
        fetchAnnouncements();
    }, [fetchAnnouncements]);

    const onCreate = useCallback(
        () => {
            setAnnouncementsLocal([
                {
                    title: 'New Announcement',
                    date: '',
                    active: true,
                    expanded: false,
                },
                ...announcementsLocal,
            ]);
        },
        [announcementsLocal]
    );

    return (
        <>
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isSaving}
                >
                <SideEffect loading={isSaving} loadingMessage="...Updating Announcements" />
            </Backdrop>
            <ManageAnnouncements
                announcements={announcementsLocal}
                onCreate={onCreate}
                onSave={onSave}
                onDelete={onDelete}
            />
        </>
    );
};

export default ManageAnnouncementsContainer;