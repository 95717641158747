import StandingsPreview from "./StandingsPreview";
import TeamName from "../Teams/TeamName";

const HEADERS = [{
    value: 'Pos',
    accessor: 'rank',
},
{ 
    value: 'Team', 
    accessor: 'players',
    align: 'left',
    boldValue: true,
    Component: ({ children: value }) => <TeamName team={{ players: value }} />
},
{ 
    value: 'Points',
    accessor: 'points',
},
{ 
    value: 'Wins',
    accessor: 'wins',
},
{ 
    value: 'Losses',
    accessor: 'losses',
},
{ 
    value: 'Ties',
    accessor: 'ties',
},
{ 
    value: 'Avg. Adj. Score',
    accessor: 'averageAdjustedScore',
    Component: ({ children: value }) => value ? value.toFixed(2) : '',
}]

const StandingsPreviewContainer = ({
    teams,
}) => {
    return (
        <StandingsPreview
            headers={HEADERS}
            rows={teams}
        />
    );
};

export default StandingsPreviewContainer