import { ToggleButtonGroup, ToggleButton, Box } from '@mui/material';
import Panel from '../Panel';
import PlayoffLeaderboard from '../PlayoffLeaderboard';
import StandingsPreview from '../StandingsPreview';

const Leaderboard = ({
    season,
    leaderboard,
    leaderboardOptions,
    onLeaderboardChange,
}) => (
    <Panel
        title="Leaderboard"
        removeContentPadding
    >
        { leaderboardOptions && leaderboardOptions.length > 1 && (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '8px'
                }}
            >
                <ToggleButtonGroup
                    size="small"
                    color="secondary"
                    exclusive
                    value={leaderboard}
                    onChange={({ target: { value }}) => onLeaderboardChange(value)}
                >
                    { leaderboardOptions.map(option => <ToggleButton value={option}>{option}</ToggleButton>)}
                </ToggleButtonGroup>
            </Box>
        )}
        {
            leaderboard === 'season' ? (
                <StandingsPreview
                    teams={season?.teams}
                />
            ) : (
                <PlayoffLeaderboard
                    playoffs={season?.playoffs}
                    playoffBoard={season?.playoffBoard}
                />
            )
        }
    </Panel>
);

export default Leaderboard;