import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import PGALTable from '../../common/PGALTable';

const HEADERS = [{
    value: 'Season',
    accessor: 'season',
    boldValue: true,
}, {
    value: 'Wins',
    accessor: 'wins',
},{
    value: 'Losses',
    accessor: 'losses',
},{
    value: 'Ties',
    accessor: 'ties',
},
{ 
    value: 'Avg. Score', 
    accessor: 'averageScore',
},
{ 
    value: 'Avg. Adj. Score', 
    accessor: 'averageAdjustedScore',
}]

const TeamStats = ({
    seasonRows,
    summaryRow,
    selectedHoles,
    onSelectHoles,
}) => (
    <>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '8px'
            }}
        >
            <ToggleButtonGroup
                size="small"
                color="secondary"
                exclusive
                value={selectedHoles}
                onChange={({ target: { value }}) => onSelectHoles(value)}
            >
                <ToggleButton value="overall">Overall</ToggleButton>
                <ToggleButton value="front">Front 9</ToggleButton>
                <ToggleButton value="back">Back 9</ToggleButton>
            </ToggleButtonGroup>
        </Box>
        <PGALTable
            rows={seasonRows}
            summaryRow={summaryRow}
            headers={HEADERS}
            color="secondary"
        />
    </>
);

export default TeamStats;