import { useMemo, useState } from "react";
import { useCallback } from "react";
import useAppContext from "../../../../hooks/useAppContext";
import TeamSeasonStats from "./TeamSeasonStats";

const TeamSeasonStatsContainer = ({
    team,
}) => {
    const { seasons: seasonData } = useAppContext();

    const [season, setSeason] = useState();
    const [seasonOption, setSeasonOption] = useState();

    const seasonOptionChange = useCallback(
        (year) => {
            const matchedSeason = seasonData.find(s => s.id == year);
            setSeason({
                ...team?.stats[year],
                handicapMeta: matchedSeason?.handicapMeta
            })
            setSeasonOption(year)
        },
        [setSeason, setSeasonOption]
    );

    const seasonOptions = useMemo(
        () => Object.keys(team?.stats || {}).filter(key => key !== 'overall'),
        [team]
    );

    return (
        <TeamSeasonStats
            team={team}
            season={season}
            seasonOption={seasonOption}
            onSeasonOptionChange={seasonOptionChange}
            seasonOptions={seasonOptions}
        />
    );
};

export default TeamSeasonStatsContainer;