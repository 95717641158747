import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import Panel from '../Panel/Panel';
import SchedulePreview from '../SchedulePreview';

const localizer = momentLocalizer(moment)



const eventPropGetter = (event, start, end, isSelected) => ({
    ...(event.bye && {
        style: {
            backgroundColor: '#000',
        },
    }),
});

const CalendarTypography = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        '.rbc-row-segment': {
            fontSize: '.75em',
            textAlign: 'center'
        }
    },
    [theme.breakpoints.up('md')]: {
        '.rbc-row-segment': {
            fontSize: '1em',
            textAlign: 'center'
        }
    },
    '.rbc-event-content': {
        textAlign: 'center'
    }
}));

const CalenderSelector = ({ calendarEvents, min, max, onSelectMatch }) => (
    <CalendarTypography>
        <Calendar
            localizer={localizer}
            events={calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            views={['month']}
            onSelectEvent={onSelectMatch}
            eventPropGetter={eventPropGetter}
            min={min}
            max={max}
        />
    </CalendarTypography>
);

// const WeekDropdown = ({
//     selectedMatch,
//     onSelectMatch,
//     calendarEvents
// }) => (
//     <Box>
//         <FormControl fullWidth>
//             <InputLabel id="weekLabel">Week</InputLabel>
//             <Select
//                 labelId="weekLabel"
//                 id="weekDropdown"
//                 value={selectedMatch?.id}
//                 label="Week"
//                 onChange={({ target: { value }}) => {
//                     onSelectMatch({ id: value }) 
//                 }}
//             >
//                 { (calendarEvents || []).map(event => <MenuItem value={event.id} selected={event.id === selectedMatch?.id}>{ event.title }</MenuItem>) }
//             </Select>
//         </FormControl>
//     </Box>
// );

// const ToggleSelection = ({
//     calendarEvents, selectedMatch, onSelectMatch, toggle, onToggle
// }) => (
//     <>
//         <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//         >
//             <ToggleButtonGroup
//                 color="secondary"
//                 value={toggle}
//                 exclusive
//                 onChange={onToggle}
//             >
//                 <ToggleButton value="DROPDOWN">Dropdown</ToggleButton>
//                 <ToggleButton value="CALENDAR">Calendar</ToggleButton>
//             </ToggleButtonGroup>
//         </Box>
//         <br />
//         { 
//             toggle === 'CALENDAR'
//                 ?  <CalenderSelector calendarEvents={calendarEvents} onSelectMatch={onSelectMatch} />
//                 : <WeekDropdown calendarEvents={calendarEvents} selectedMatch={selectedMatch} onSelectMatch={onSelectMatch} />
//         }
//     </>
// )

const Schedule = ({ calendarEvents, selectedMatch, onSelectMatch, toggle, onToggle }) => (
    <Panel
        title="Schedule"
    >
        <CalenderSelector
            calendarEvents={calendarEvents}
            onSelectMatch={onSelectMatch}
        />
        <br />
        <SchedulePreview match={selectedMatch} />
    </Panel>
);

export default Schedule;