import { useMemo } from 'react';
import { useState } from 'react';
import Leaderboard from './Leaderboard'

const LeaderboardContainer = ({
    season
}) => {
    const [leaderboard, setLeaderboard] = useState('season');
    const options = useMemo(
        () => {
            const optionsArray = ["season"];
            if (season.playoffs && season.playoffs.length > 0) {
                optionsArray.push('playoffs');
            }
            return optionsArray;
        },
        [season]
    )
    return (
        <Leaderboard
            leaderboardOptions={options}
            leaderboard={leaderboard}
            onLeaderboardChange={setLeaderboard}
            season={season}
        />
    );
};

export default LeaderboardContainer;