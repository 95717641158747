import { getAdmin, postAdmin, putAdmin } from "./httpAction";

export const fetchPreview = async (season, req = {}) => {
    const data = await postAdmin(`/admin/seasons/${season}/preview`, req);
    return data;
}

export const fetchHandicapMetadata = async () => {
    return await getAdmin('/admin/seasons/handicap-metadata');
}

export const updateMatch = async (season, req = {}) => {
    const data = await putAdmin(`/admin/seasons/${season}/matches`, req);
    return data;
}

export const updateSeason = async (season, req = {}) => {
    const data = await putAdmin(`/admin/seasons/${season}`, req);
    return data;
}