import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from "react";
import { updateMatch } from '../../../api/pgalAdminApis';
import useAppContext from "../../../hooks/useAppContext";
import PGALDropdown from "../../PGALDropdown";
import { PROX_TYPE } from "../../ProxIcon/ProxIcon";
import Matchup from "./Matchup";

const HOLE_ITEMS = [
    { value: 1, text: 1 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
    { value: 4, text: 4 },
    { value: 5, text: 5 },
    { value: 6, text: 6 },
    { value: 7, text: 7 },
    { value: 8, text: 8 },
    { value: 9, text: 9 },
    { value: 10, text: 10 },
    { value: 11, text: 11 },
    { value: 12, text: 12 },
    { value: 13, text: 13 },
    { value: 14, text: 14 },
    { value: 15, text: 15 },
    { value: 16, text: 16 },
    { value: 17, text: 17 },
    { value: 18, text: 18 },
];

const DEFAULT_FORM = {
    date: undefined,
    holes: undefined,
    playOff: false,
    prox1hole: undefined,
    prox1type: undefined,
    prox2hole: undefined,
    prox2type: undefined,
    matchups: {},
}

const mapMatchupToForm = (matchup) => {

    const form = {
        id: matchup?.id,
        time: matchup?.time,
    }

    if (!matchup.scores || matchup.scores.length === 0) {
        return form;
    }

    return {
        ...form,
        home: matchup.scores[0].teamId,
        homeScore: matchup.scores[0].score,
        homeRinger: matchup.scores[0].ringer,
        homeForfeit: matchup.scores[0].forfeit,
        away: matchup.scores[1].teamId,
        awayScore: matchup.scores[1].score,
        awayRinger: matchup.scores[1].ringer,
        awayForfeit: matchup.scores[1].forfeit,
    }
};

const mapToForm = (match) => {
    return {
        date: match.date,
        holes: match.holes,
        playOff: match.playOff,
        prox1hole: match.prox[0].hole,
        prox1type: match.prox[0].type,
        prox1winner: match.prox[0].winner,
        prox2hole: match.prox[1].hole,
        prox2type: match.prox[1].type,
        prox2winner: match.prox[1].winner,
        matchups: match.matchups.reduce((prev, curr) => ({
            ...prev,
            [curr.id]: mapMatchupToForm(curr),
        }), {}),
    }
};

const mapFromFormMatchup = (matchupForm, matchup) => ({
    ...matchup,
    time: matchupForm.time,
    scores: [{
        teamId: matchupForm.home,
        score: matchupForm.homeScore,
        ringer: matchupForm.homeRinger,
        forfeit: matchupForm.homeForfeit,
    }, {
        teamId: matchupForm.away,
        score: matchupForm.awayScore,
        ringer: matchupForm.awayRinger,
        forfeit: matchupForm.awayForfeit,
    }],
})

const mapFromForm = (matchForm, match) => ({
    ...match,
    date: matchForm.date,
    holes: matchForm.holes,
    playOff: Boolean(matchForm.playOff),
    prox: [{
        ...match.prox[0],
        hole: matchForm.prox1hole,
        type: matchForm.prox1type,
        winner: matchForm.prox1winner,
    }, {
        ...match.prox[1],
        hole: matchForm.prox2hole,
        type: matchForm.prox2type,
        winner: matchForm.prox2winner,
    }],
    matchups: match.matchups.map(matchup => ({
        ...matchup,
        ...mapFromFormMatchup(matchForm.matchups[matchup.id], matchup)
    })),
});

const swapTime = (prev, curr, listObj) => {
    if(curr['time'] !== prev['time']) {
        let swapObject = Object.values(listObj).find(object => object['time'] === curr['time'])
        if (swapObject) {
            swapObject['time'] = prev['time'];
        }
    }
}

const swapTeam = (field, prev, curr, listObj) => {
    const teamId = field === 'away' ? 'away' : 'home';
    const altTeamId = field === 'away' ? 'home' : 'away';
    if(curr[teamId] !== prev[teamId]) {
        let swapObject =  Object.values(listObj).find(object => object[teamId] === curr[teamId]);
        let prevSwapObject =  Object.values(listObj).find(object => object[teamId] === prev[teamId]);
        if (swapObject) {
            const tempScore = swapObject[`${teamId}Score`];
            const tempRinger = swapObject[`${teamId}Ringer`];
            swapObject[teamId] = prev[teamId];
            swapObject[`${teamId}Score`] = prev[`${teamId}Score`];
            swapObject[`${teamId}Ringer`] = prev[`${teamId}Ringer`];
            prevSwapObject[`${teamId}Score`] = tempScore;
            prevSwapObject[`${teamId}Ringer`] = tempRinger;
        } else {
            swapObject = Object.values(listObj).find(object => object[altTeamId] === curr[teamId]) || {};
            prevSwapObject = Object.values(listObj).find(object => object[teamId] === prev[teamId]) || {};

            const tempScore = swapObject[`${altTeamId}Score`];
            const tempRinger = swapObject[`${altTeamId}Ringer`];
            swapObject[altTeamId] = prev[teamId];
            swapObject[`${altTeamId}Score`] = prev[`${teamId}Score`];
            swapObject[`${altTeamId}Ringer`] = prev[`${teamId}Ringer`];
            prevSwapObject[`${teamId}Score`] = tempScore;
            prevSwapObject[`${teamId}Ringer`] = tempRinger;
        }
    }
}

const ManageMatchup = ({
    match,
    teams,
}) => {
    const [matchForm, setMatchForm] = useState({ ...DEFAULT_FORM });
    const [saving, setSaving] = useState(false);
    const { players, fetchMatches, season } = useAppContext();

    const onSetFormField = useCallback(
        (field, value) => {
            setMatchForm({
                ...matchForm,
                [field]: value,
            });
        },
        [matchForm, setMatchForm]
    );

    useEffect(
        () => {
            setMatchForm(mapToForm(match));
        },
        [match]
    );
    const proxHoleItems = useMemo(
        () => {
            if (!matchForm) {
                return []
            }
            if (matchForm.holes === 'BACK') {
                return HOLE_ITEMS.filter(({ value }) => value >= 10)
            }
            
            return HOLE_ITEMS.filter(({ value }) => value < 10)
        },
        [matchForm]
    );

    const onChangeMatchup = useCallback(
        (matchupId, field, value) => {
            const prevMatch = JSON.parse(JSON.stringify(matchForm.matchups[matchupId]));
            const matchup = { 
                ...JSON.parse(JSON.stringify(matchForm.matchups[matchupId])), 
                [field]: value 
            };
            
            const newMatchups =  JSON.parse(JSON.stringify(matchForm.matchups))

            // if time changed, swap with other matchup
            swapTime(prevMatch, matchup, newMatchups);

            // if home changed, swap with other matchup
            if (matchup.home !== prevMatch.home) {
                swapTeam('home', prevMatch, matchup, newMatchups);
            } else if (matchup.away !== prevMatch.away) {
                // if away changed, swap with other matchup
                swapTeam('away', prevMatch, matchup, newMatchups);
            }

            onSetFormField('matchups', {
                ...newMatchups,
                [matchupId]: {
                    ...newMatchups[matchupId],
                    [field]: value,
                },
            });
        },
        [matchForm, onSetFormField]
    )

    const saveEnabled = useMemo(
        () => {
            const initialForm = mapToForm(match);
            return JSON.stringify(initialForm) !== JSON.stringify(matchForm);
        },
        [matchForm, match]
    );

    const onSave = useCallback(
        async () => {
            setSaving(true);
            const request = mapFromForm(matchForm, match);
            await updateMatch(season.id, request);
            fetchMatches();
            setSaving(false);
            window.scrollTo(0, 0)
        },
        [matchForm, match, setSaving, fetchMatches, season]
    );

    const playerOptions = useMemo(
        () => {
            const options = (players || []).map(({ id, name }) => ({ text: name, value: id }));
            return [
                { text: 'Select a Player', value: '' },
                ...options,
            ];
        },
        [players]
    );

    if (!matchForm) {
        return null;
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={saving}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography>
                        Saving...
                    </Typography>
                    <CircularProgress color="inherit" />
                </Box>
            </Backdrop>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}
            >
                <Box>
                    <Typography
                        variant="h4"
                        gutterBottom
                    >
                        Week {match.week}
                    </Typography>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                        <Typography>Week Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{ backgroundColor: 'white' }}
                        >
                            <Stack component="form" noValidate spacing={3} sx={{ width: '100%' }}>
                                <TextField
                                    id="date"
                                    label="Date"
                                    type="date"
                                    value={moment(matchForm.date).format("yyyy-MM-DD")}
                                    onChange={({ target: { value } }) => {
                                        onSetFormField('date', moment(value).format("yyyy/MM/DD"))
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        marginTop: '8px',
                                    }}
                                />
                                <PGALDropdown
                                    id={'holes-dropdown'}
                                    label="Holes"
                                    value={matchForm?.holes}
                                    onChange={(value) => {
                                        onSetFormField('holes', value)
                                    }}
                                    menuItems={[{ value: "FRONT", text: "Front" }, { value: "BACK", text: "Back" }]}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={matchForm.playOff}
                                            onChange={({ target: { checked }}) => {
                                                onSetFormField('playOff', checked)
                                            }}
                                        />
                                    }
                                    label="Play-Off Week?"
                                />
                                <Divider />
                                <PGALDropdown
                                    id={'prox1-holes-dropdown'}
                                    label="Prox 1 Hole"
                                    value={matchForm?.prox1hole}
                                    onChange={(value) => {
                                        onSetFormField('prox1hole', value)
                                    }}
                                    menuItems={proxHoleItems}
                                />
                                <PGALDropdown
                                    id={'prox1-type-dropdown'}
                                    label="Prox 1 Type"
                                    value={matchForm?.prox1type}
                                    onChange={(value) => {
                                        onSetFormField('prox1type', value)
                                    }}
                                    menuItems={Object.values(PROX_TYPE).map(type => ({ text: type, value: type }))}
                                />
                                <PGALDropdown
                                    id={'prox1-winner-dropdown'}
                                    label="Prox 1 Winner"
                                    value={matchForm?.prox1winner}
                                    onChange={(value) => {
                                        onSetFormField('prox1winner', value)
                                    }}
                                    menuItems={playerOptions}
                                />
                                <Divider />
                                <PGALDropdown
                                    id={'prox2-holes-dropdown'}
                                    label="Prox 2 Hole"
                                    value={matchForm?.prox2hole}
                                    onChange={(value) => {
                                        onSetFormField('prox2hole', value)
                                    }}
                                    menuItems={proxHoleItems}
                                />
                                <PGALDropdown
                                    id={'prox2-type-dropdown'}
                                    label="Prox 2 Type"
                                    value={matchForm?.prox2type}
                                    onChange={(value) => {
                                        onSetFormField('prox2type', value)
                                    }}
                                    menuItems={Object.values(PROX_TYPE).map(type => ({ text: type, value: type }))}
                                />
                                <PGALDropdown
                                    id={'prox2-winner-dropdown'}
                                    label="Prox 2 Winner"
                                    value={matchForm?.prox2winner}
                                    onChange={(value) => {
                                        onSetFormField('prox2winner', value)
                                    }}
                                    menuItems={playerOptions}
                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <br />
                    {
                        Object.values(matchForm.matchups).map((vsMatch, ndx) => (
                            <Matchup
                                key={vsMatch.id}
                                ndx={ndx}
                                matchup={vsMatch}
                                teams={teams}
                                onMatchupChange={onChangeMatchup}
                            />
                        ))
                    }
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}
                >
                    <Button sx={{ marginRight: '16px' }} size="large" variant="contained" color="tertiary" disabled={!saveEnabled} onClick={onSave}>
                        Save
                    </Button>
                    
                    <Button sx={{ marginLeft: '16px' }} size="large" variant="outlined">Cancel</Button>
                </Box>
            </Box>
        </>
    );
};

export default ManageMatchup;