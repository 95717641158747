import React, { useMemo, useState } from 'react';
import TeamHandicap from './TeamHandicap';

const TeamHandicapContainer = ({
    season,
}) => {
    const [selectedWeek, setSelectedWeek] = useState();


    const filteredTeamScores = useMemo(
        () => { 
            if (season?.scores) {
                const sortedArray = [...season.scores];
                sortedArray.sort((a, b) => a.week - b.week)
                return sortedArray;
            }
            return undefined;
        },
        [season]
    );

    const weekOptions = useMemo(() => (season.handicaps || []).map(({ week }) => ({
        text: `Week ${week}`,
        value: week,
    })), [filteredTeamScores]);

    const selectedHandicap = useMemo(
        () => (season.handicaps || []).find(handicap => handicap.week === selectedWeek),
        [season, selectedWeek]
    );

    let totalScoreString = '';
    let averageScoreString = '';
    let rawHandicapString = '';
    let finalHandicapString = '';

    const maxHandicap = season?.handicapMeta?.maxHandicap;

    if (selectedHandicap) {
        const scores = selectedHandicap.weeks.map(week => filteredTeamScores.find(score => score.week === week).score);

        if (scores.length > 0) {
            const scoresString = scores.join(' + ');
            const totalScore = scores.reduce((prev, curr) => prev+ Number(curr), 0);
            totalScoreString = `${scoresString} = ${totalScore}`

            const averageScore = (totalScore / scores.length).toFixed(2);
            averageScoreString = `${totalScore} / ${scores.length} = ${averageScore}`;

            const rawHandicap = (averageScore - 36).toFixed(2);
            rawHandicapString = `${averageScore} - 36 = ${rawHandicap}`;

            const finalHandicap = rawHandicap >= 0 ? Math.floor(rawHandicap) : Math.ceil(rawHandicap)
            finalHandicapString = `${rawHandicap >= 0 ? 'floor' : 'ceil'}(${rawHandicap}) = ${finalHandicap}`;
        }
    }

    return (
        <TeamHandicap
            weekOptions={weekOptions}
            selectedWeek={selectedWeek}
            onChangeSelectedWeek={setSelectedWeek}
            selectedHandicap={selectedHandicap}
            filteredTeamScores={filteredTeamScores}
            totalScoreString={totalScoreString}
            averageScoreString={averageScoreString}
            rawHandicapString={rawHandicapString}
            finalHandicapString={finalHandicapString}
            maxHandicap={maxHandicap}
        />
    );
};

export default TeamHandicapContainer;