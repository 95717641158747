
import PGALTable from '../common/PGALTable';


const StandingsPreview = ({
    headers,
    rows
}) => {
    if (!rows) {
        return null;
    }
    
    return (
        <PGALTable
            color="secondary"
            headers={headers}
            rows={rows}
        />
    );
};

export default StandingsPreview;