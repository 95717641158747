import { useEffect, useMemo } from "react";
import { useCallback } from "react";
import { useState } from "react";
import useAppContext from "../../../hooks/useAppContext";
import { enrichSchedule } from "./matchup.utils";
import ScheduleGenerator from "./ScheduleGenerator";
import { 
    addMatchupToSchedule,
    findMatchup,
    generateBlankSchedule,
    generateFixedSchedule,
    generateMatchups,
    removeMatchupFromSchedule,
} from './scheduleGenerator.utils';

const DEFAULT_FORM = {
    startTee: '4:30'
}

const ScheduleGeneratorContainer = () => {
    const { seasons } = useAppContext();

    const [form, setForm] = useState(DEFAULT_FORM)
    const [selectedSeason, setSelectedSeason] = useState();
    const [matchups, setMatchups] = useState([]);
    const [schedule, setSchedule] = useState();
    const [teams, setTeams] = useState();
    const [translations, setTranslations] = useState({});

    const formChange = useCallback((field, value) => {
        setForm({
            ...form,
            [field]: value,
        })
    }, [form, setForm]);

    const addByeWeek = useCallback(() => {
        setForm({
            ...form,
            byeWeeks: [...(form.byeWeeks || []), (new Date()).toLocaleDateString()]
        })
    }, [form])

    const removeByeWeek = useCallback((ndxToRemove) => {
        setForm({
            ...form,
            byeWeeks: (form.byeWeeks || []).filter((x,ndx) => ndx !== ndxToRemove)
        })
    }, [form])

    const changeByeWeek = useCallback((value, ndx) => {
        const newForm = { ...form }
        newForm.byeWeeks[ndx] = value;
        setForm(newForm)
    }, [form])

    const selectSeason = useCallback((id) => {
        const season = seasons.find(s => s.id == id);
        setTeams(season.teams);
        setSelectedSeason(season);
    }, [seasons])

    const generate = useCallback(() => {
        const newSchedule = generateFixedSchedule(teams, schedule, matchups);
        const enrichedSchedule = enrichSchedule(newSchedule, form, selectedSeason.id);
        setSchedule(enrichedSchedule)
        setMatchups([])
        setTranslations(
            teams.reduce((prev, curr) => ({
                ...prev,
                [curr.id]: curr.id,
            }), {})
        )
    }, [teams, schedule, matchups, form, selectedSeason]);

    const reset = useCallback(() => {
        setSchedule(generateBlankSchedule(teams));
        setMatchups(generateMatchups(teams, true));
        setForm(DEFAULT_FORM)
    }, [teams, form]);

    const scheduleMatchup = useCallback((matchId, week) => {
        const matchToSchedule = matchups.find(({ id }) => id === matchId);
        addMatchupToSchedule(matchToSchedule, schedule, week);
        // Remove from the available matchups
        setMatchups(matchups.filter(({ id }) => id !== matchId));
        setSchedule(schedule);
    }, [matchups, schedule]);

    const removeMatchup = useCallback((matchupId) => {
        const matchup = findMatchup(matchupId, schedule);
        removeMatchupFromSchedule(matchupId, schedule);
        setMatchups([
            ...matchups,
            matchup,
        ])
    }, [matchups, schedule])

    useEffect(() => {
        if (teams) {
            reset();
        }
    }, [teams]);

    const changeTranslation = useCallback((key, value) => {
        const swapKey = Object.keys(translations).find(k => translations[k] === value);
        if (swapKey !== key) {
            const currValue = translations[key];
            const newTranslations = {
                ...translations,
                [swapKey]: currValue,
                [key]: value,
            };
            setTranslations(newTranslations)
        }
    }, [translations])

    const translatedSchedule = useMemo(() => (schedule || []).map(week => ({
        ...week,
        matchups: week.matchups.map(matchup => ({
            ...matchup,
            teams: matchup.teams.map(team => ({
                ...team,
                teamId: translations[team.teamId],
            }))
        }))
    })), [translations, schedule]);

    const swapWeeks = useCallback((ndx, targetNdx) => {
        const newSchedule = [
            ...schedule
        ]
        const tempMatchups = newSchedule[ndx].matchups;
        newSchedule[ndx].matchups = newSchedule[targetNdx].matchups;
        newSchedule[targetNdx].matchups = tempMatchups;
        setSchedule(newSchedule);
    }, [schedule]);

    return (
        <ScheduleGenerator
            form={form}
            onFormChange={formChange}
            season={selectedSeason}
            seasons={seasons}
            setSeason={selectSeason}
            weeks={translatedSchedule}
            matchups={matchups}
            translations={translations}
            onGenerate={generate}
            onReset={reset}
            onScheduleMatchup={scheduleMatchup}
            onRemoveMatchup={removeMatchup}
            onAddByeWeek={addByeWeek}
            onRemoveByeWeek={removeByeWeek}
            onChangeByeWeek={changeByeWeek}
            onMoveUp={(ndx) => swapWeeks(ndx,ndx-1)}
            onMoveDown={(ndx) => swapWeeks(ndx,ndx+1)}
            onChangeTranslation={changeTranslation}
        />
    )
};

export default ScheduleGeneratorContainer;