/**
 * Represents the base team object used to generate the schedule
 * 
 * @typedef { Object } Team
 * 
 * @property { string } id
 */

import { ArrayUtils } from "../../../utils/array.utils";
import { addWeeksToDate } from "../../../utils/date.utils";

/**
 * Represents the matchup between two teams
 * 
 * @typedef { Object } TeamMatchup
 * 
 * @property { string } id
 * @property { Team[] } teams
 */

/**
 * Represents the matchup between two teams
 * 
 * @typedef { Object } EnrichedTeamMatchup
 * 
 * @property { string } id
 * @property { Team[] } teams
 * @property { number[] } availableWeeks
 */

/**
 * Represents a week in the schedule
 * 
 * @typedef { Object } Week
 * 
 * @property { string } week            the week in the schedule
 * @property { TeamMatchup[] } matchups the matchups in the week
 */

/**
 * Represents the schedule
 * 
 * @typedef { Week[] } Schedule
 */

/**
 * Generates a unique list of matchups across the teams
 * @param { Team[] } originalTeams 
 * @returns { TeamMatchup }
 */
export const generateMatchups = (originalTeams, randomize) => {
    let teams = [...originalTeams]
    if (randomize) {
        teams = ArrayUtils.randomize(teams);
    }
    const list = [...teams];
    const matchups = [];
    while (list.length > 1) {
        const team = list.shift();
        list.forEach(item => {
            matchups.push([team, item]);
        });
    }

    return matchups.map((matchups, ndx) => ({
        id: `m${ndx+1}`,
        teams: matchups,
    }));
}

/**
 * Generates a blank schedule
 * 
 * @param { Team[] } teams 
 * @returns { Schedule }
 */
export const generateBlankSchedule = teams => {
    const numWeeks = teams.length-1;
    const schedule = [];
    for(let i = 0; i < numWeeks; i++) {
        schedule.push({ week: i+1, matchups: [], teams: [] });
    }
    return schedule;
}

/**
 * Validates if there is any overlap between the team
 * 
 * @param { Team[] } teamsA 
 * @param { Team[] } teamsB 
 * @returns { boolean } true if there is an overlap, otherwise false
 */
const isOverlapInTeams = (teamsA, teamsB) => {
    return teamsA[0].id === teamsB[0].id
        || teamsA[0].id === teamsB[1].id
        || teamsA[1].id === teamsB[0].id
        || teamsA[1].id === teamsB[1].id
}

/**
 * Finds the available weeks for a given matchup considering that a team
 * cannot play twice in the same week and that you cannot go over the # of slots (ie. tee times)
 * @param { TeamMatchup } matchup   the matchup we are trying to find the available paths for
 * @param { Schedule } schedule     the schedule that maintains the *current* state of all scheduled matches
 * @param { number } slots          the number of tee times that are available per week
 * @returns { number[] }
 */
const findAvailableWeeks = (matchup, schedule, slots) => {
    return schedule
        .filter(({ matchups, teams }) => {
            return matchups.length < slots && !(teams.includes(matchup.teams[0].id) || teams.includes(matchup.teams[1].id))
        })
        .map(({ week }) => week);
}

/**
 * Searches for the matchup in the current schedule
 * 
 * @param { TeamMatchup } matchup   the matchup we are searching for
 * @param { Schedule } schedule     the schedule that maintains the *current* state of all scheduled matches
 * @returns { Week }
 */
const findMatchupWeek = (matchupId, schedule) => schedule.find(week => week.matchups.some(({ id }) => id === matchupId));

export const findMatchup = (matchupId, schedule) => schedule.reduce((prev, curr) => {
    if (prev) {
        return prev;
    }
    return curr.matchups.find(({ id }) => id === matchupId);
}, null)

/**
 * Enriches the team matchup by looking for the available matchups based on what is currently scheduled
 * 
 * @param { TeamMatchup } matchup   the matchup we are enriching
 * @param { Schedule } schedule     the schedule that maintains the *current* state of all scheduled matches
 * @param { number } slots          the number of slots available in each week
 * @returns { EnrichedTeamMatchup }
 */
const enrichMatchup = (matchup, schedule, slots) => ({
    ...matchup,
    availableWeeks: findAvailableWeeks(matchup, schedule, slots),
})

/**
 * Schedules the matchup provided for the week provided
 * 
 * @param { EnrichedTeamMatchup } matchup   matchup we are scheduling
 * @param { Schedule } schedule             where we are scheduling the matchup
 * @param { number } week                   the week we are scheduling the matchup for
 */
export const addMatchupToSchedule = (matchup, schedule, availableWeek) => {
    const week = schedule.find(({ week }) => week === availableWeek);
    const scheduledMatchup = { ...matchup };
    delete scheduledMatchup.availableWeeks;
    week.teams = [...(week.teams || []), matchup.teams[0].id, matchup.teams[1].id]
    week.matchups.push(scheduledMatchup); 
}

/**
 * Removes a matchup from the schedule
 * 
 * @param { EnrichedTeamMatchup } matchup   The matchup we are removing
 * @param { Schedule } schedule             the schedule that maintains the *current* state of all scheduled matches
 */
export const removeMatchupFromSchedule = (matchupId, schedule) => {
    const week = findMatchupWeek(matchupId, schedule);
    const matchup = week.matchups.find(({ id }) => id === matchupId)
    week.matchups = week.matchups.filter(({ id }) => id !== matchupId);
    week.teams = (week.teams || []).filter(id => id !== matchup.teams[0].id && id !== matchup.teams[1].id)
}

/**
 * Generates a schedule in a round-robin fashion where each team should play each week but not
 * play the same team more than once
 * 
 * Using two stacks:
 * - scheduled      holds matches that have been scheduled with the available weeks (paths) that have not been taken,
 *                  when these are popped we should NOT re-establish the available weeks. This is because we want to
 *                  take the *next* available path, since the previous path we went down led to an invalid state
 * 
 * - unscheduled    holds the matches that have not been scheduled, when these are popped we should always re-establish
 *                  the available weeks
 * 
 * 1. RANDOMIZE TEAMS IF REQUESTED (that will give us randomized matchups)
 * 2. GENERATE BLANK SCHEDULE
 * 3. GENERATE MATCHUPS
 * 4. [UNIMPLEMENTED] SET FIXED MATCHUPS IN SCHEDULE AND REMOVE FROM MATCHUPS
 * 5. UNSCHEDULED_STACK = MATCHUPS, SCHEDULED_STACK = []
 * 6. GET FIRST MATCH AND FIND AVAILABLE_WEEKS
 * 7. IF AVAILABLE_WEEKS IS NOT EMPTY:
 *      7.1. GET THE FIRST AVAILABLE WEEK
 *      7.2. SCHEDULE THE MATCH
 *      7.3. PUSH THE MATCH TO SCHEDULED_STACK (with the enriched state, ie. AVAILABLE_WEEKS)
 *      7.4. GET NEXT MATCHUP AND FIND AVAILABLE_WEEKS
 *      7.5. [GO TO STEP 7]
 * 8. ELSE (uh oh, we need to step back)
 *      8.1. PUSH THE CURRENT MATCHUP BACK TO THE UNSCHEDULED STACK (without AVAILABLE_WEEKS)
 *      8.2. GET THE MOST RECENT SCHEDULED MATCH (ie. pop that stack)
 *      8.3. UNSCHEDULE
 *      8.4. [GO TO STEP 7]
 * 
 * @param { Team[] } teams      original team list
 * @param { boolean } randomize if true, the list will be randomized. Defaults to false
 * @returns { Schedule } that is fully populated
 */
export const generateFixedSchedule = (teams, originalSchedule, originalMatchups) => {
    const schedule = JSON.parse(JSON.stringify(originalSchedule));
    const matchups = JSON.parse(JSON.stringify(originalMatchups));
    const slots = teams.length / 2;
    
    const unscheduled = [...matchups];
    const scheduled = [];
    const getNextEnrichedMatchup = () => {
        if (unscheduled.length === 0) {
            return null;
        }

        const matchup = unscheduled.pop();
        // console.log(">>> POPPING NEXT MATCH", matchup.id)
        return enrichMatchup(matchup, schedule, slots);
    }

    let enrichedMatchup = getNextEnrichedMatchup();

    // STEP 7 & 8
    while(enrichedMatchup) {
        if (enrichedMatchup.availableWeeks.length > 0) {
            const availableWeek = enrichedMatchup.availableWeeks.pop();
            addMatchupToSchedule(enrichedMatchup, schedule, availableWeek);
            scheduled.push(enrichedMatchup);
            enrichedMatchup = getNextEnrichedMatchup();
        } else {
            unscheduled.push({ ...enrichedMatchup, availableWeeks: undefined });
            enrichedMatchup = scheduled.pop();
            if (enrichedMatchup) {
                // console.log(">>> REWINDING TO MATCH", enrichedMatchup.id)
                removeMatchupFromSchedule(enrichedMatchup.id, schedule);
            }
        }
    }

    return schedule;
}

export const generateSchedule = (teams, randomize) => {
    const schedule = generateBlankSchedule(teams);
    const matchups = generateMatchups(teams, randomize);
    
    return generateFixedSchedule(teams, schedule, matchups)
}