import Home from "./Home";
import useAppContext from "../../hooks/useAppContext";

const HomeContainer = () => {
    const {
        teams = [],
        season = {},
        playoffBoard = []
    } = useAppContext();
    return (
        <Home
            teams={teams}
            season={season}
            playoffBoard={playoffBoard}
        />
    )
};

export default HomeContainer;