import { useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useContext } from "react";
import useAppContext from "../../hooks/useAppContext";
import PreviousSeasons from "./PreviousSeasons";

const PreviousSeasonsContainer = ({
    children
}) => {
    const [season, setSeason] = useState();
    const { seasons = [] } = useAppContext();

    const selectSeason = useCallback(
        (selectedYear) => {
            const selectedSeason = seasons.find(({ id }) => id === selectedYear);
            if (selectedSeason) {
                setSeason(selectedSeason);
            }
        },
        [setSeason, seasons]
    );

    const seasonsOptions = useMemo(
        () => seasons.filter(({ active }) => !active).map(({ id }) => ({ text: id, value: id })),
        [seasons]
    );

    return (
        <PreviousSeasons
            seasonsOptions={seasonsOptions}
            season={season}
            onSelectSeason={selectSeason}
        />
    );
}

export default PreviousSeasonsContainer;