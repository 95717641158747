import { Typography, styled } from "@mui/material";

const StyledTypography = styled(Typography)(({ theme, score, rationalized = false, bold = false, fontSize = '1em', handicap }) => {
    let color = theme.palette.tertiaryGray.main;

    if (rationalized) {
        if (score > 0) {
            color = handicap
                ? theme.palette.tertiary.light
                : theme.palette.secondary.light;
        }
        if (score < 0) {
            color = handicap
                ? theme.palette.secondary.light
                : theme.palette.tertiary.light;
        }
    }

    return ({
        color,
        paddingLeft: theme.spacing(1),
        fontSize,
        fontWeight: bold ? 'bold' : 'normal',
    })
});

const Score = ({
    bold,
    children,
    onConvertScore,
    fontSize,
    rationalized,
    handicap
}) => (
    <StyledTypography
        score={children}
        bold={bold}
        rationalized={rationalized}
        fontSize={fontSize}
        handicap={handicap}
    >
        { onConvertScore 
            ? onConvertScore(children) 
            : children
        }
    </StyledTypography>
);
export default Score;