import { Stack, TextField, FormControlLabel, Checkbox, Divider, Button } from "@mui/material";
import { styled } from "@mui/system";
import Panel from "../../Panel";
import PGALDropdown from "../../PGALDropdown";

const RuleHeader = styled('span')(({ theme }) => ({
    fontWeight: 'bold',
}));

const RuleDescription = styled('span')(({ theme }) => ({
    fontSize: 'small',
    marginTop: '0 !important',
}));

const ValueDescription = styled('span')(({ theme }) => ({
    fontSize: 'small',
    marginTop: '0 !important',
    marginLeft: `${theme.spacing(1)} !important`,
    marginRight: `${theme.spacing(1)} !important`,
    fontStyle: 'italic',
}));

const Rule = ({
    name,
    description,
    valueDescription,
    children,
}) => (
    <>
        <RuleHeader>
            { name }
        </RuleHeader>
        <RuleDescription>
            { description }
        </RuleDescription>
        { children }
        {
            valueDescription && (
                <ValueDescription>
                    { valueDescription }
                </ValueDescription>
            )
        }

    </>
);

const ManageHandicap = ({
    form,
    onFormChange,
    metadataOptions,
    handicapMetadata,
    onPreview,
    onSave,
}) => (
    <Panel
            title="Handicap Rules"
            variant="h6"
    >
        <Stack component="form" noValidate spacing={3} sx={{ width: '100%' }}>
        <Rule
                name="Max Handicap"
                description="The maximum +/- handicap applied to a team."
            >
                <TextField
                    id="maxHandicap"
                    label="Max Handicap"
                    type="number"
                    value={form?.maxHandicap}
                    onChange={({ target: { value } }) => {
                        onFormChange('maxHandicap', value)
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Rule>
            <Rule
                name="Handicap Capping"
                description="Determines how a score contributes to the handicap calculation."
                valueDescription={
                    form.cappedHandicap
                    ? 'If the score exceeds the max handicap, the score will be capped at the max handicap for calculating future handicaps. For example, if the max handicap is 5 and someone shoots a 47, we only consider a 41 to count towards their handicap calculation'
                    : 'If the score exceeds the max handicap, the score will be NOT capped at the max handicap for calculating future handicaps. For example, if the max handicap is 5 and someone shoots a 47, we consider a 47 to count towards their handicap calculation'
                }
            >
                <FormControlLabel
                    label="Cap Handicap?"
                    control={
                        <Checkbox
                            checked={form.cappedHandicap}
                            onChange={({ target: { checked }}) => {
                                onFormChange('cappedHandicap', checked)
                            }}
                        />
                    }
                />
            </Rule>
            <Rule
                name="Handicap Round Strategy"
                description="Defines how the handicap is rounded to a whole number."
                valueDescription={handicapMetadata.roundStrategy[form?.roundStrategy]?.description}
            >
                <PGALDropdown
                    id={'roundStrategy'}
                    label="Handicap Round Strategy"
                    value={form?.roundStrategy}
                    onChange={(value) => {
                        onFormChange('roundStrategy', value)
                    }}
                    menuItems={metadataOptions.roundStrategy}
                    size="small"
                />
            </Rule>
            <Rule
                name="Ringer Strategy"
                description="Defines what how to adjust a team's score when they bring a sub."
                valueDescription={handicapMetadata.ringerStrategy[form?.ringerStrategy]?.description}
            >
                <PGALDropdown
                    id={'ringerStrategy'}
                    label="Ringer Strategy"
                    value={form?.ringerStrategy}
                    onChange={(value) => {
                        onFormChange('ringerStrategy', value)
                    }}
                    menuItems={metadataOptions.ringerStrategy}
                    size="small"
                />
            </Rule>
            <Rule
                name="Handicap Algorithm"
                description="Defines what how to adjust a team's score when they bring a sub."
                valueDescription={handicapMetadata.algorithm[form?.algorithm]?.description}
            >
                <PGALDropdown
                    id={'ringerStrategy'}
                    label="Handicap Algorithm"
                    value={form?.algorithm}
                    onChange={(value) => {
                        onFormChange('algorithm', value)
                    }}
                    menuItems={metadataOptions.algorithm}
                    size="small"
                />
            </Rule>
            <Divider />
            <Button 
                size="large"
                onClick={onPreview}
                color="warning"
                variant="contained"
            >
                Preview Season
            </Button>
            { onSave && (
                <Button 
                    size="large"
                    onClick={onSave}
                    color="success"
                    variant="contained"
                >
                    Save Season
                </Button>
            )}
        </Stack>
    </Panel>
);

export default ManageHandicap;
