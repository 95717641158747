import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import ProxIcon from "../ProxIcon";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const StyledBox = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    border: '1px solid black',
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    fontSize: '.75em',
}));

const HoleGridItem = styled(Grid)(({ theme }) => {
    const colors = {
        background: theme.palette.secondary.contrastText,
        color: theme.palette.tertiary.dark,
    };

    if(theme.palette.mode === 'dark') {
        colors.background = theme.palette.tertiary.main;
        colors.color = theme.palette.tertiary.contrastText;
    }

    return {
        borderRadius: theme.spacing(1),
        fontWeight: '900',
        fontSize: '2.5em',
        padding: '0px !important',
        textShadow: '1px 1px black',
        textAlign: 'center',
        ...colors,
    }
});

const WinnerBox = styled(Typography)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    fontSize: '1.20em',
}))

const MyGridItem = styled(Grid)(({ theme }) => ({
    padding: '0px !important',
})) 

const ProxBox = ({ type, hole, winner }) => (
    <StyledBox>
        <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                padding: '8px',
            }}
        >
            <MyGridItem
                item
                xs={2}
            >
                <ProxIcon
                    fill="#ffffff"
                    type={type}
                />
            </MyGridItem>
            <MyGridItem
                item
                xs={7}
                sx={{
                    fontWeight: 'bold',
                    lineHeight: '100%',
                    textAlign: 'center'
                }}
            >
                { type.toUpperCase().replaceAll('_', ' ') }
            </MyGridItem>
            <HoleGridItem
                item
                xs={3}
                justifyContent="center"
                alignItems="center"
            >
                { hole }
            </HoleGridItem>
        </Grid>
        {
            winner && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <EmojiEventsIcon
                        sx={{
                            color: 'primary.light'
                        }}
                    />
                    <WinnerBox>{ winner }</WinnerBox>
                    <EmojiEventsIcon
                        sx={{
                            color: 'primary.light'
                        }}
                    />
                </Box>
            )
        }
    </StyledBox>
);

export default ProxBox;