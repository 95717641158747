import ManageMatchups from "./ManageMatchups"
import { useCallback, useMemo, useState } from "react";
import useAppContext from "../../../hooks/useAppContext";

const ManageMatchupsContainer = () => {
    const { matches, teams } = useAppContext();
    const [selectedMatch, setSelectedMatch] = useState();

    const filteredMatches = useMemo(
        () => (matches || []).filter(({ bye }) => !bye),
        [matches]
    );

    const onSelectMatch = useCallback(
        (id) => {
            setSelectedMatch(matches.find(match => match.id === id))
        },
        [setSelectedMatch, matches]
    );

    if (!matches || matches.length === 0) {
        return null;
    }

    return (
        <ManageMatchups
            teams={teams}
            matches={filteredMatches}
            selectedMatch={selectedMatch}
            onSelectMatch={onSelectMatch}
        />
    );
};

export default ManageMatchupsContainer;