import { Backdrop, Box, Typography, CircularProgress } from "@mui/material";

const Loader = ({
    text,
    active,
}) => (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={active}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Typography>
                { text }
            </Typography>
            <CircularProgress color="inherit" />
        </Box>
    </Backdrop>
);

export default Loader;