import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, alpha } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import PGALDropdown from '../../../PGALDropdown';
import { convertHandicapText } from '../../../../utils/teamUtils';
import { MathComponent } from 'mathjax-react'

const HandicapHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    fontWeight: 'bold',
    textAlign: 'center',
}));

const StyledMathComponent = styled(MathComponent)(() => ({
    '.MathJax': {
        fontSize: '.5em !important'
    }
}));

const StyledMathGridItem = styled(Grid)(() => ({
    fontSize: '.65em !important'
}));

const StyledCell = styled(TableCell)(() => ({
    padding: 0,
    textAlign: 'center',
}));

const StyledRow = styled(TableRow)(({ theme, highlight, outline}) => ({
    backgroundColor: highlight ? theme.palette.success.light : 'none',
    border: outline ? `2px solid ${ theme.palette.tertiary.main }`: 'none',
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
    padding: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
}));

const TeamHandicap = ({
    weekOptions,
    selectedWeek,
    onChangeSelectedWeek,
    selectedHandicap,
    filteredTeamScores,
    totalScoreString,
    averageScoreString,
    rawHandicapString,
    finalHandicapString,
    maxHandicap
}) => {
    if (!weekOptions || weekOptions.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <i>No Handicap Calculations Available</i>
            </Box>
        );
    }
    
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingBottom: '16px',
                }}
            >
                <PGALDropdown
                    id={'week-handicap-dropdown'}
                    label="Week"
                    value={selectedWeek}
                    onChange={onChangeSelectedWeek}
                    menuItems={weekOptions}
                    fullWidth
                />
                {
                    selectedWeek && selectedHandicap && (
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Grid item xs={4}>
                                <Box
                                    sx={{
                                        paddingTop: '16px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledHeaderCell>Key</StyledHeaderCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledRow highlight><StyledCell>Selected Week</StyledCell></StyledRow>
                                            <StyledRow outline><StyledCell>Used in Calculation</StyledCell></StyledRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <HandicapHeader
                                    variant="h6"
                                >
                                    {`Handicap: ${convertHandicapText(selectedHandicap.handicap, selectedHandicap.ringer)}`}
                                </HandicapHeader>
                                <Typography
                                    variant="subtitle2"
                                >
                                    * Handicaps are capped at ±{maxHandicap}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }
            </Box>
            {
                selectedWeek && selectedHandicap && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={4}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledHeaderCell>Week</StyledHeaderCell>
                                        <StyledHeaderCell>Score</StyledHeaderCell>
                                        <StyledHeaderCell>HC</StyledHeaderCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        filteredTeamScores.map(score => {
                                            return (
                                            <StyledRow outline={selectedHandicap.weeks.includes(score.week)} highlight={selectedWeek === score.week}>
                                                <StyledCell>{score.week}</StyledCell>
                                                <StyledCell>{`${score.score || ''}${score.ringer ? '*' : ''}`}</StyledCell>
                                                <StyledCell>{convertHandicapText(score.score - score.adjustedScore)}</StyledCell>
                                            </StyledRow>
                                        )})
                                    }
                                </TableBody>
                            </Table>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    fontSize: '.8em',
                                    paddingTop: '8px',
                                    textAlign: 'center',
                                }}
                            >
                                * Substitute
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid 
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    paddingBottom: '16px'
                                }}
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">1. Select the Eligible Weeks for calculating the handicap</Typography>
                                </Grid>
                                <Grid item xs={7} sx={{ fontWeight: 'bold', fontSize: '.8em !important', textAlign: 'center'}}>
                                    { selectedHandicap?.weeks.map(x => Number(x)).sort((a, b) => a-b).join(', ')}
                                </Grid>
                            </Grid>
                            <Grid 
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    paddingBottom: '16px'
                                }}
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">2. Calculate the total score</Typography>
                                </Grid>
                                <StyledMathGridItem item xs={7}>
                                    <StyledMathComponent tex={String.raw`${totalScoreString}`} />
                                </StyledMathGridItem>
                            </Grid>
                            <Grid 
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    paddingBottom: '16px'
                                }}
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">3. Average the score across the rounds</Typography>
                                </Grid>
                                <StyledMathGridItem item xs={7}>
                                    <StyledMathComponent tex={String.raw`${averageScoreString}`} />
                                </StyledMathGridItem>
                            </Grid>
                            <Grid 
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    paddingBottom: '16px'
                                }}
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">4. Subtract Par 36 to get the raw handicap</Typography>
                                </Grid>
                                <StyledMathGridItem item xs={7}>
                                    <StyledMathComponent tex={String.raw`${rawHandicapString}`} />
                                </StyledMathGridItem>
                            </Grid>
                            <Grid 
                                container
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">5. Handicaps always round toward an even handicap, ie. 0</Typography>
                                </Grid>
                                <StyledMathGridItem item xs={7}>
                                    <StyledMathComponent tex={String.raw`${finalHandicapString}`} />
                                </StyledMathGridItem>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </>
    );
};

export default TeamHandicap;