import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import Panel from '../Panel/Panel';
import Team from './Team';
import TeamHandicap from './Team/TeamHandicap';
import TeamName from './TeamName';

const Teams = ({
    teams,
    selectedTeam,
    onSelectTeam
}) => (
    <Panel
        title="Teams"
    >
        <Box>
            <FormControl fullWidth>
                <InputLabel id="teamLabel">Team</InputLabel>
                <Select
                    labelId="teamLabel"
                    id="teamDropdown"
                    value={selectedTeam?.id}
                    label="Team"
                    onChange={({ target: { value }}) => {
                        onSelectTeam(value) 
                    }}
                >
                    { (teams || []).map(team => <MenuItem key={team.id} value={team.id}><TeamName team={team} /></MenuItem>) }
                </Select>
            </FormControl>
        </Box>
        {
            selectedTeam && (
                <>
                    <br />
                    <Divider />
                    <br />
                    <Team team={selectedTeam} />
                </>
            )
        }
    </Panel>
);

export default Teams;