import { Box, Checkbox, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import { useMemo} from "react";
import Panel from "../../Panel";
import PGALDropdown from "../../PGALDropdown";
import TeamName from '../../Teams/TeamName';

const TEE_TIMES = [
    { text: '4:30', value: '4:30' },
    { text: '4:38', value: '4:38' },
    { text: '4:46', value: '4:46' },
    { text: '4:54', value: '4:54' },
    { text: '5:02', value: '5:02' },
    { text: '5:10', value: '5:10' },
    { text: '5:18', value: '5:18' },
    { text: '5:26', value: '5:26' },
];

const TeamGrid = ({
    form,
    teamOptions,
    teamFormField,
    scoreFormField,
    ringerFormField,
    forfeitFormField,
    forfeitDisabled,
    label,
    onFormChange,
}) => (
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
    >
        <Grid item xs={5} sx={{ width: '100% !important' }}>
            <PGALDropdown
                id={`${form.id}-${teamFormField}-dropdown`}
                label={`${label} Team`}
                value={(form || {})[teamFormField]}
                onChange={(value) => {
                    onFormChange(teamFormField, value)
                }}
                menuItems={teamOptions}
                fullWidth
            />
        </Grid>
        <Grid item xs={3}>
            <TextField
                sx={{ marginLeft: '16px',  }}
                id={`${form.id}-${scoreFormField}-score`}
                label={`${label} Score`}
                type="text"
                value={(form || {})[scoreFormField]}
                onChange={({ target: { value } }) => {
                    onFormChange(scoreFormField, value)
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={4}>
            <Box
                sx={{ marginLeft: '16px',  }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={(form || {})[ringerFormField]}
                            onChange={({ target: { checked }}) => {
                                onFormChange(ringerFormField, checked)
                            }}
                        />
                    }
                    labelPlacement="right"
                    label="Ringer?"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={(form || {})[forfeitFormField]}
                            onChange={({ target: { checked }}) => {
                                onFormChange(forfeitFormField, checked)
                            }}
                            disabled={forfeitDisabled}
                        />
                    }
                    labelPlacement="right"
                    label="Forfeit?"
                />
            </Box>
        </Grid>
    </Grid>
)

const Matchup = ({
    matchup,
    teams,
    ndx,
    onMatchupChange,
}) => {
    const onSetFormField = (field, value) => {
        onMatchupChange(matchup.id, field, value);
    };

    const teamOptions = useMemo(
        () => (teams || []).map(team => ({
            value: team.id,
            text: <TeamName team={team} />
        })),
        [teams]
    );

    return (
        <Panel
            title={`Matchup #${ndx+1}`}
            variant="h6"
        >
            <Stack component="form" noValidate spacing={3} sx={{ width: '100%' }}>
                <PGALDropdown
                    id={`${matchup.id}-tee-dropdown`}
                    label="Tee Time"
                    value={matchup?.time}
                    onChange={(value) => {
                        onSetFormField('time', value)
                    }}
                    menuItems={TEE_TIMES}
                />
                <TeamGrid
                    form={matchup}
                    onFormChange={onSetFormField}
                    teamOptions={teamOptions}
                    teamFormField="away"
                    scoreFormField="awayScore"
                    ringerFormField="awayRinger"
                    forfeitFormField="awayForfeit"
                    forfeitDisabled={matchup?.homeForfeit}
                    label="Away"
                />
                <TeamGrid
                    form={matchup}
                    onFormChange={onSetFormField}
                    teamOptions={teamOptions}
                    teamFormField="home"
                    scoreFormField="homeScore"
                    ringerFormField="homeRinger"
                    forfeitFormField="homeForfeit"
                    forfeitDisabled={matchup?.awayForfeit}
                    label="Home"
                />
            </Stack>
        </Panel>
    );
};

export default Matchup;