export const renderStreak = (streak) => {
    if (streak === 0) return '-';
    if (streak > 0) return `W${streak}`
    return `L${Math.abs(streak)}`
}

const getStreak = array => {
    const sortedArray = [...array].sort().reverse();
    let streak = 1;
    let prevValue = sortedArray[0];
    for(let i = 1; i < sortedArray.length; i++) {
        if(sortedArray[i] === prevValue -1) {
            streak++;
            prevValue = sortedArray[i];
        } else {
            break;
        }
    }

    return streak;
}

export const calculateStreak = team => {
    const maxWin = Math.max(...(team.wins || []));
    const maxLoss = Math.max(...(team.losses || []));
    const maxTie = Math.max(...(team.ties || []));
    
    if (maxWin > maxLoss && maxWin > maxTie) {
        return getStreak(team.wins);
    } else if (maxLoss > maxWin && maxLoss > maxTie) {
        return getStreak(team.losses) * -1
    }
    return 0;
}

export const generateRecord = team => `${(team.wins || []).length}-${(team.losses || []).length}-${(team.ties || []).length}`

export const convertHandicapText = (handicap, ringer = false) => {
    if (ringer) {
        return '*0';
    }

    if (handicap === 0) {
        return '0';
    }

    if (!handicap) {
        return 'N/A';
    }

    if (handicap > 0) {
        return `-${handicap}`
    } 
    
    return `+${handicap*-1}`;
}

export const convertScoreText = score => {
    if (score > 0) {
        return `+${score}`;
    }
    if (score < 0) {
        return `-${score*-1}`;
    }
    return 'E'
}