import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import Panel from '../Panel/Panel';
import PGALMarkdown from '../PGALMarkdown';

const CONTENT_LOCATION = "Foxford Hills Golf Club<br />6800 S. Rawson Bridge Rd.<br />Cary, IL 60013"
const CONTENT_CONTACT = "Tim Matesi<br /><a href=\"tel:+18477577988\">(847)757-7988</a><br /><timothy.matesi@gmail.com>"
const CONTENT_DATES = "Mondays<br />4:30 PM - 5:29 PM <br />April 15 - August 19 (weather pending)"
const CONTENT_FEE = "$100/Golfer, $200/team"
const CONTENT_GREEN_FEE = "$23"
const CONTENT_TEES = "Gold (72.2/137/6,618)"
const CONTENT_FORMAT = "2-Man 9-Hole Scramble\n"
+ " - Both players hit their ball, choose the best shot, play from there and repeat until the hole is complete\n"
+ " - Must stay within one club length, no closer to the hole (within 2\" when on the green)\n"
+ " - Must stay in the same lie as the original ball. If the original is in the bunker you must stay in the bunker, if the original is in the rough you must stay in the rough, etc...\n"
+ " - **PUTT EVERYTHING OUT**, there are no gimmies\n"
+ " - Max on a hole is 3+ par"
const CONTENT_HANDICAPS = "Below are the handicap rules that will be in effect for the season:<br />"
const CONTENT_SUBS = "If a player cannot make it for a matchup, there are two options:"
+ "\n- Play by yourself and keep your handicap. You only play ONE shot"
+ "\n- Bring a sub. This will impact your handicap for the week but will not count towards your handicap calculation:"
+ "\n"
+ "\n"
const CONTENT_SCHEDULE = "17 Total Weeks\n"
+ " - 15 Regular Season Weeks\n"
+ "    - Every team will play each team at least once\n"
+ " - 2 Playoff Weeks\n"
const CONTENT_SCORING = "Final round scores will be assessed with both teams handicaps applied:<br /><br />Win: 2pts<br />Tie: 1pts<br />Loss: 0pts\n"
+ "<br /><br />The first two weeks will remain unscored until handicaps have been established."
+ "<br /><br /><b>You must complete at least 7 holes</b> for the round to count. In the case of a rainout, any matchup that does not complete 7 holes will be rescheduled and replayed from the beginning."
const CONTENT_PLAYOFF_FORMAT = "**Every team makes the playoffs**\n"
+ "<br /><br/>"
+ "18 hole playoff (played across two weeks) in a FedEx cup style format where the top ranked teams will start off with stroke advantages\n"
+ " - 1st: -6\n"
+ " - 2nd: -4\n"
+ " - 3rd: -3\n"
+ " - 4th: -2\n"
+ " - 5th: -1\n"
+ " - 6th-16th: E\n\n"
+ "Handicaps will apply to both rounds, however teams will be using their lowest handicap of the season"
+ "<br /><br />"
+ "Tiebreakers will be decided by selecting a random hole until the tie is broken"
const CONTENT_COURSE_RULES = "Fences, yards, and tall grass are considered hazards, not OB. Treat these as you would a red or yellow stake\n"
const CONTENT_PRIZES = "<ul>Season:</u><br />1st: $1150<br />2nd: $650<br />Random (3-16): $200<br /><br />Raffle: Possible options include a wedge, hats, shirts, ..."
+ "<br /><br />"
+ "<ul>Weekly Prox:</u><br />$23 (free green fee) per prox.\n"
+ " - <b>Closest To</b>: Must be a GIR\n"
+ " - <b>Closest Approach</b>: Must be a GIR (2nd shot for Par 4, 3rd shot for Par 5)\n"
+ " - <b>Longest Putt</b>: The ball must be on the green\n"
+ " - <b>Longest Drive</b>: The ball must be in the fairway\n\n"
+ "***Note:** The same person cannot win both proxes for the week. In the event "
+ "that this happens, the 2nd place player on the 2nd prox will win."

const CONTENT_MAP = [{
    title: "Date & Time",
    content: CONTENT_DATES,
}, {
    title: "Location",
    content: CONTENT_LOCATION,
}, {
    title: "Contact",
    content: CONTENT_CONTACT,
}, {
    title: "Leauge Dues",
    content: CONTENT_FEE,
}, {
    title: "Weekly Green Fees",
    content: CONTENT_GREEN_FEE,
}, {
    title: "Schedule",
    content: CONTENT_SCHEDULE,
}, {
    title: "League Format",
    content: CONTENT_FORMAT,
}, {
    title: "Scoring",
    content: CONTENT_SCORING,
}, {
    title: "Tees",
    content: CONTENT_TEES,
}, {
    title: "Handicaps",
    content: CONTENT_HANDICAPS,
    dynamicContent: (season, handicapMetadata) => {
        if (season && handicapMetadata) {
            const algorithm = handicapMetadata?.algorithm[season?.handicapMeta?.handicapAlgorithm];
            const roundStrategy = handicapMetadata?.roundStrategy[season?.handicapMeta?.handicapRoundStrategy];
            const ringerStrategy = handicapMetadata?.ringerStrategy[season?.handicapMeta?.ringerStrategy];

            if (!algorithm || !roundStrategy || !ringerStrategy) {
                return "";
            }

            return `- **<u>Overview:</u>** `
                + algorithm.name
                + "<br /><br />"
                + algorithm.description
                + "<br /><br />"
                + `\n- **<u>Score Rounding:</u>** `
                + roundStrategy.name
                + "<br /><br />"
                + `\n- **<u>Ringers:</u>** `
                + ringerStrategy.name
                + "<br /><br />"
                + ringerStrategy.description
                + "<br /><br />"
                + `\n- **<u>Max Handicap:</u>** `
                + season.handicapMeta.maxHandicap
                + "<br /><br />"
                + `\n- **<u>Capped Handicaps:</u>** `
                + (season.handicapMeta.capHandicapCalc ? 'Yes' : 'No')
                + "<br /><br />"
                + (season.handicapMeta.capHandicapCalc ? 'Scores above the max handicap will be capped at the max handicap (eg. if the max is a 5 and you shoot a +7, then only a score of +5 counts towards your handicap' : 'Scores above the max handicap will count in full towards the handicap calculation')
        }
        return '';
    }
}, {
    title: "Playoff Format",
    content: CONTENT_PLAYOFF_FORMAT,
}, {
    title: "Subtitutes",
    content: CONTENT_SUBS,
    dynamicContent: (season, handicapMetadata) => {
        if (season && handicapMetadata) {
            const ringerStrategy = handicapMetadata?.ringerStrategy[season?.handicapMeta?.ringerStrategy];
            return ringerStrategy?.description;
        }
        return '';
    }
}, {
    title: "Course Rules",
    content: CONTENT_COURSE_RULES,
}, {
    title: "Prizes",
    content: CONTENT_PRIZES,
}];

const Section = ({ title, content, dynamicContent }) => (
    <Grid container sx={{ paddingBottom: '16px' }} spacing={1}>
        <Grid item xs={3} md={2}>
            <Typography
                variant="span"
                sx={{
                    fontWeight: 'bold'
                }}
            >
                { `${title}:` }
            </Typography>
        </Grid>
        <Grid item xs={9} md={10}>
            <PGALMarkdown content={content} />
            { dynamicContent && <PGALMarkdown content={dynamicContent} /> }
            
        </Grid>
    </Grid>
)

const LeagueInfo = ({
    season,
    handicapMetadata,
}) => (
    <Panel
        title={`${season?.id || ''} League Info`}
    >
        <Box
            justifyContent="center"
            width="100%"
        >  
            {
                CONTENT_MAP.map(({ title, content, dynamicContent }, ndx) => (
                    <>
                        <Section
                            title={title}
                            content={content}
                            dynamicContent={dynamicContent && dynamicContent(season, handicapMetadata)}
                        />
                        { ndx !== CONTENT_MAP.length-1 && (
                            <>
                                <Divider />
                                <br />
                            </>
                        )}
                    </>
                ))
            }
        </Box>
    </Panel>
);

export default LeagueInfo;