import { Divider, Typography } from "@mui/material";
import pgalogo from '../../images/pgal-logo.png';

const AppFooter = () => (
    <>
        <Divider />
        <Typography
            variant="subtitle2"
            align="center"
            sx={{
                paddingTop: '4px'
            }}
        >
            &copy; 2022 PGAL
            <br />
            <a
                href="/"
            >
                <img
                    src={pgalogo}
                    height={100}
                    alt="whoops"
                />
            </a>
        </Typography>
    </>
);

export default AppFooter;