import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, styled } from "@mui/system";
import pgalogo from "../../../images/pgal-logo.png";
import { withRouter } from "react-router-dom";
import AppBarDrawer from "./AppBarDrawer";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
    minWidth: theme.minWidth,
    color: theme.palette.mode === 'dark' ? theme.palette.tertiary.main : theme.palette.secondary.dark
}));

const StyledLogoText = styled(Typography)(({ theme }) => ({
    color: theme.palette.logo.main,
    textShadow: `1px 1px ${theme.palette.logo.contrastText}`,
}));

const PGALAppBar = ({
    pages,
    adminPages,
    active,
    onOpen,
    onClose,
    onAdminClick,
}) => (
    <StyledAppBar
        position="fixed"
    >
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <AppBarDrawer
                    pages={pages}
                    adminPages={adminPages}
                    active={active}
                    onOpen={onOpen}
                    onClose={onClose}
                    onAdminClick={onAdminClick}
                />
                <Box
                    sx={{
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'row',
                        display: 'flex'
                    }}
                >
                    <a
                        href="/"
                    >
                        <img
                            style={{
                                height: "2.5em",
                                marginRight: '8px',
                            }}
                            src={pgalogo}
                            alt={"whoops"}
                    />
                    </a>
                    <StyledLogoText
                        variant="h4"
                        noWrap
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none'},
                            fontFamily: 'Clarendo',
                            fontWeight: 'bold',
                            letterSpacing: '-0.05em',
                            padding: 0,
                            marginTop: '8px'
                        }}
                    >
                        PGAL
                    </StyledLogoText>
                </Box>
            </Toolbar>
        </Container>
    </StyledAppBar>
);

PGALAppBar.propTypes = {

}

export default withRouter(PGALAppBar);
