import * as React from 'react';
import { useState } from 'react';
import PGALAppBar from "./PGALAppBar";

const PGALAppBarContainer = ({ pages, adminPages, onAdminClick, }) => {
    const [active, setActive] = useState(false);

    const handleOpenMenu = React.useCallback(() => {
        setActive(true);
    }, [setActive]);

    const handleCloseMenu = React.useCallback(() => {
        setActive(false);
    }, [setActive]);

    return (
        <PGALAppBar
            pages={pages}
            adminPages={adminPages}
            active={active}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            onAdminClick={onAdminClick}
        />
    );
};

export default PGALAppBarContainer;
