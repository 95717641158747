import useAppContext from "../../hooks/useAppContext";
import ProxBox from './ProxBox';

const ProxBoxContainer = ({ type, hole, winner }) => {
    const { players } = useAppContext();

    const winnerText = players.find(({ id }) => id === winner)?.name

    return (
        <ProxBox
            type={type}
            hole={hole}
            winner={winnerText}
        />
    )
};

export default ProxBoxContainer;