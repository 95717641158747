import { Box } from "@mui/material";
import { convertHandicapText } from "../../../../utils/teamUtils";
import PGALTable from "../../../common/PGALTable";

const HEADERS = [{
    value: 'Week',
    accessor: 'week'
}, {
    value: 'Holes',
    accessor: 'holes'
}, {
    value: 'Handicap',
    accessor: 'handicap',
    Component: ({ children: value, row }) => convertHandicapText(value, row?.ringer)
}, {
    value: 'Score',
    accessor: 'score'
}, {
    value: 'Adj. Score',
    accessor: 'adjustedScore'
}, {
    value: 'Result',
    accessor: 'result'
}]

const TeamsScores = ({
    scores,
}) => {
    if (!scores || scores.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <i>No Scores Available</i>
            </Box>
        );
    }

    return (
        <PGALTable
            color="secondary"
            rows={scores}
            headers={HEADERS}
        />
    )
}

export default TeamsScores;