import { useEffect, useState } from "react";
import useAppContext from "../../hooks/useAppContext";
import SchedulePreview from "./SchedulePreview";

const SchedulePreviewContainer = ({ match }) => {
    const [latestMatch, setLatestMatch] = useState();
    const { upcomingMatch } = useAppContext();

    useEffect(() => {
        if (!match) {
            setLatestMatch(upcomingMatch);
        } else {
            setLatestMatch(match)
        }
    }, [match, upcomingMatch]);
    
    return (
        <SchedulePreview
            match={latestMatch}
        />
    );
};

export default SchedulePreviewContainer;