import { Button, Box } from "@mui/material";
import Panel from "../../Panel";
import ManageAnnouncement from './ManageAnnouncement'

const ManageAnnouncements = ({
    announcements,
    onSave,
    onDelete,
    onCreate,
}) => (
    <Panel
        title="Manage Announcements"
    >
        <Box
            sx={{
                display: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Button 
                size="large"
                onClick={onCreate}
                color="success"
                variant="contained"
            >
                Create New Announcement
            </Button>
        </Box>
        <br />
        {
            (announcements || []).map(announcement => (
                <ManageAnnouncement
                    key={announcement.id}
                    announcement={announcement}
                    onSave={onSave}
                    onDelete={onDelete}
                />
            ))
        }
    </Panel>
);

export default ManageAnnouncements;