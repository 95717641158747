import { Switch, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/system";
import pgalogo from "../../../images/pgal-logo.png";
import ThemeSwitch from "../../ThemeSwitch";

const Header = styled('div')(({ theme }) => ({
    width: 'auto',
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        width: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    [theme.breakpoints.down('md')]: {
        display: 'none'
    },
}));

const MainToolbar = styled(Toolbar)(({ theme }) => ({

}));

const SecondaryToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
    marginBottom: `8px`
}));

const MainTypography = styled(Typography)(({ theme }) => ({
    flex: 1,
    color: theme.palette.secondary.dark,
    fontWeight: 500,
}));

const PGALHeader = ({ pages }) => (
    <Header>
        <MainToolbar>
            <img
                style={{
                    height: "3.5em",
                    marginRight: '8px',
                }}
                src={pgalogo}
                alt={"whoops"}
            />
            <MainTypography
                variant="h4"
                color="inherit"
                align="center"
                noWrap
            >
                Pretty God Awful League
            </MainTypography>
            <ThemeSwitch />
        </MainToolbar>
        <hr />
        <SecondaryToolbar variant="dense">
            {pages.map(({ title, onClick }) => (
            <Typography
                color="inherit"
                noWrap key={title}
                onClick={onClick}
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                        color: 'secondary.main'
                    }
                }}
            >
                {title}
            </Typography>
            ))}
        </SecondaryToolbar>
    </Header>
);

export default PGALHeader;