import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const PGALDatePicker = (props) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            sx={props?.sx}
            { ...props }
            renderInput={(props) => (
                <TextField
                    {...props}
                    sx={{
                        padding: '0!important',
                    }}
                />
            )}
        />
    </LocalizationProvider>
);

export default PGALDatePicker;