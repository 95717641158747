import { Auth } from "aws-amplify";
import { useState } from "react";

const useUser = () => {
    const [user, setUser] = useState(undefined);
    
    Auth.currentUserInfo().then(({ username = undefined }) => {
        setUser(username);
    });

    return {
        user,
    }
}

export default useUser;