import { useMemo, useState, useEffect } from "react";
import Panel from "../Panel";
import SchedulePreview from "../SchedulePreview";
import useAppContext from "../../hooks/useAppContext";
import { Box, Button } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const getTitle = (match, upcomingMatch) => {
    if(match?.playOff) {
        return 'Playoffs';
    }

    if (match?.id === upcomingMatch?.id) {
        return "This Week's Matchups";
    }

    return match?.bye ? `Bye Week` : `Week ${match?.week} Matchups`
}

const HomeScheduleOverview = () => {
    const [selectedWeek, setSelectedWeek] = useState();
    const { matches, upcomingMatch } = useAppContext();

    useEffect(() => {
        if (upcomingMatch && matches) {
            const matchIndex = matches.findIndex(({ id }) => id === upcomingMatch.id)
            setSelectedWeek(matchIndex);
        }
    }, [upcomingMatch, matches]);

    const match = useMemo(
        () => (matches || [])[selectedWeek],
        [selectedWeek, matches]
    );

    console.log(">>> match", match)

    const title = getTitle(match, upcomingMatch)

    const onSelectPrev = () => {
        setSelectedWeek(selectedWeek-1)
    }
    const onSelectNext = () => {
        setSelectedWeek(selectedWeek+1)
    }

    const maxWeeks = (matches || []).length - 1
    return (
            <Panel
                title={title}
            >
                {
                    match && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Button variant="contained" color="tertiary" startIcon={<ChevronLeftIcon />} disabled={selectedWeek <= 0} onClick={onSelectPrev}>
                                See Prev Week
                            </Button>
                            <Button variant="contained" color="tertiary" endIcon={<ChevronRightIcon />} disabled={selectedWeek >= maxWeeks} onClick={onSelectNext}>
                                See Next Week
                            </Button>
                        </Box>
                    )
                }

                <SchedulePreview match={match} />
            </Panel>
    );
};

export default HomeScheduleOverview;