import { useCallback, useEffect, useState } from "react";
import { FORM_MODE } from '../../../../utils/enums'
import ManageAnnouncement from "./ManageAnnouncement";

const mapAnnouncementToForm = (announcement) => ({
    id: announcement?.id,
    title: announcement?.title,
    date: announcement?.date,
    expanded: announcement?.expanded,
    active: announcement?.active,
    content: announcement?.content,
});

const DEFAULT_FORM = {
    id: '',
    title: '',
    date: '',
    expanded: '',
    active: false,
    content: false,
};

const ManageAnnouncementContainer = ({
    announcement,
    onSave: save,
    onDelete: del,
}) => {
    const [form, setForm] = useState(DEFAULT_FORM);
    const [formMode, setFormMode] = useState(FORM_MODE.READ);
    const [selectedTab, setSelectedTab] = useState("write");

    useEffect(
        () => {
            setForm({
                ...mapAnnouncementToForm(announcement)
            });
        },
        [announcement, setForm]
    )

    const onFormChange = useCallback(
        (field, value) => {
            setForm({
                ...form,
                [field]: value,
            });
        },
        [form, setForm]
    );

    const onEdit = useCallback(
        () => {
            setFormMode(FORM_MODE.UPDATE);
        },
        [setFormMode]
    );

    const onCancel = useCallback(
        () => {
            setFormMode(FORM_MODE.READ);
            setForm({
                ...mapAnnouncementToForm(announcement)
            });
        },
        [setFormMode, setForm, announcement]
    );

    const onSave = useCallback(
        () => {
            save(announcement?.id, form)
        },
        [form, announcement, save]
    );

    const onDelete = useCallback(
        () => {
            del(announcement?.id)
        },
        [announcement, del]
    );

    return (
        <ManageAnnouncement
            form={form}
            onFormChange={onFormChange}
            formMode={formMode}
            disabled={formMode === FORM_MODE.READ}
            selectedTab={selectedTab}
            onSelectedTabChange={setSelectedTab}
            onEdit={onEdit}
            onCancel={onCancel}
            onSave={onSave}
            onDelete={onDelete}
        />
    );
};

export default ManageAnnouncementContainer;