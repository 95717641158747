import { get } from "./httpAction"

const URI_SEASON = '/seasons';
const URI_TEAMS = '/teams';

export const fetchSeason = async (season) => {
    return await get(`${URI_SEASON}/${season}`);
}

export const fetchSeasons = async () => {
    return await get(URI_SEASON);
}

export const fetchTeam = async (teamId, enriched) => {
    return await get(`${URI_TEAMS}/${teamId}`, { enriched: 'true' });
}