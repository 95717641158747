import { Box, Button, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import PGALDatePicker from "../../common/PGALDatePicker";
import PGALDivider from "../../common/PGALDivider";
import Panel from "../../Panel";
import PGALDropdown from "../../PGALDropdown";
import ScheduledWeek from "./ScheduledWeek";

const ScheduleGenerator = ({
    season,
    seasons,
    form,
    onFormChange,
    setSeason,
    weeks,
    matchups,
    onGenerate,
    onReset,
    onScheduleMatchup,
    onRemoveMatchup,
    onAddByeWeek,
    onRemoveByeWeek,
    onChangeByeWeek,
    onMoveUp,
    onMoveDown,
    translations,
    onChangeTranslation,
}) => (
    <Panel
        title="Schedule Generator"
    >
        <PGALDropdown
            label="Season"
            value={season?.id}
            onChange={setSeason}
            menuItems={(seasons || []).map(({ id }) => ({ value: id, text: id }))}
            size='small'
            fullWidth
        />
        { season && (
            <>
                <PGALDivider
                    text="Schedule Overview"
                />
                <Box>
                    <Typography>
                        <b>Teams:</b>
                        { ' ' }
                        { season?.teams.length }
                    </Typography>
                    <Typography>
                        <b>Weeks:</b>
                        { ' ' }
                        { weeks?.length }
                    </Typography>
                    <br />
                    <TextField
                        value={form?.startTee}
                        onChange={({ target: { value }}) => onFormChange('startTee', value)}
                        label="First Tee Time"
                    />
                    <br />
                    <br />
                    <PGALDatePicker
                        label="Start Date"
                        value={form?.startDate}
                        onChange={value => onFormChange('startDate', value)}
                    />
                    <br />
                    <br />
                    {
                        (form.byeWeeks || []).map((week, ndx) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 2,
                                }}
                            >
                                <PGALDatePicker
                                    label={`Bye Week ${ndx}`}
                                    value={week}
                                    onChange={value => onChangeByeWeek(value, ndx)}
                                />
                                <Button
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    onClick={() => onRemoveByeWeek(ndx)}
                                    sx={{
                                        marginLeft: 2
                                    }}
                                >
                                    Remove
                                </Button>
                            </Box>
                        ))
                    }
                    <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        onClick={onAddByeWeek}
                    >
                        Add Bye Week
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 2
                        }}
                    >
                        <Button
                            onClick={onGenerate}
                            variant="contained"
                            color="tertiary"
                            sx={{
                                marginRight: 5,
                            }}
                        >
                            Generate
                        </Button>
                        <Button
                            onClick={onReset}
                            variant="contained"
                            color="secondary"
                            sx={{
                                marginLeft: 5,
                            }}
                        >
                            Reset
                        </Button>
                    </Box>
                </Box>
                <PGALDivider
                    text="Translations"
                />
                { Object.keys(translations || {}).map(team => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        key={team}
                    >
                        <Typography
                            sx={{
                                marginRight: 3
                            }}
                        >
                            {team}:
                        </Typography>
                        <PGALDropdown
                            value={translations[team]}
                            size="small"
                            menuItems={Object.keys(translations).map(x => ({ value: x, text: x, disabled: x === translations[team] }))}
                            onChange={value => onChangeTranslation(team, value)}
                        />
                    </Box>
                ))}
                <PGALDivider
                    text="Week Details"
                />
                {(weeks || []).map((week, ndx) => (
                    <Box>
                        {
                            ndx !== 0 && (
                                <Button onClick={() => onMoveUp(ndx)}>Move Up</Button>
                            )
                        }
                        {
                            ndx !== weeks.length-1 && (
                                <Button onClick={() => onMoveDown(ndx)}>Move Down</Button>
                            )
                        }
                        <ScheduledWeek
                            key={week.week}
                            week={week}
                            matchups={matchups}
                            onScheduleMatchup={onScheduleMatchup}
                            onRemoveMatchup={onRemoveMatchup}
                        />
                    </Box>
                ))}
            </>
        )}
    </Panel>
);

export default ScheduleGenerator;