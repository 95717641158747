import React, { useState } from 'react';
import { styled } from "@mui/system";
import { Card, CardContent, CardHeader, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PGALMarkdown from '../PGALMarkdown';

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    borderRadius: 0,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    background: theme.palette.mode === 'light' ? theme.palette.background.announcement.main : theme.palette.grey[200],
    color: theme.palette.mode === 'light' ? theme.palette.background.announcement.contrastText : theme.palette.text.contrastText,
}));

const StyledSubheader = styled('div')(({ theme }) => ({
    color: theme.palette.spice.main
}))

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    background: theme.palette.spice.main,
    color: theme.palette.spice.contrastText,
    '&:hover': {
      background: theme.palette.spice.main,
    }
  }));

const Announcement = ({ title, date, content, defaultExpanded }) => {
    const [expanded, setExpanded] = useState(defaultExpanded);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    return (
        <StyledCard>
            <CardHeader
                title={title}
                subheader={<StyledSubheader>{date}</StyledSubheader>}
                action={
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <StyledCardContent>
                    <PGALMarkdown content={content} />
                </StyledCardContent>
            </Collapse>
        </StyledCard>
    )
};

export default Announcement;