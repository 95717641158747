import { styled } from "@mui/system";

const StyledBody = styled('div')(({ theme }) => ({
    width: 'auto',
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        width: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    [theme.breakpoints.down('md')]: {
        width: 'auto',
        marginLeft: 0,
        marginRight: 0,
        minWidth: theme.minWidth,
        marginTop: theme.spacing(7),
    },
}));

const Body = ({ children }) => (
    <StyledBody>
        { children }
    </StyledBody>
);

export default Body;