import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TeamStats from './TeamStats';
import TeamSeasonStats from './TeamSeasonStats';


const Team = ({
    team,
    seasonRows,
    summaryRow,
    selectedHoles,
    onSelectHoles,
}) => (
    <Box
        sx={{
            padding: '12px',
            paddingTop: '0px'
        }}
    >
        <TeamStats
            stats={team?.stats}
            seasonRows={seasonRows}
            summaryRow={summaryRow}
            selectedHoles={selectedHoles}
            onSelectHoles={onSelectHoles}
        />
        <br />
        <TeamSeasonStats
            team={team}
        />
    </Box>
);

export default Team;