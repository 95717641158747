import Schedule from "./Schedule";
import useAppContext from "../../hooks/useAppContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "@mui/material";

const ScheduleContainer = () => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    const { teams, matches, upcomingMatch } = useAppContext();
    const [selectedMatch, setSelectedMatch] = useState();
    const [userSelected, setUserSelected] = useState(false);
    const [userToggled, setUserToggled] = useState(false);
    const [toggle, setToggle] = useState();
    const calendarEvents = useMemo(() => ((matches || []).map(match => ({
            title: match.bye ? 'BYE' : `Week ${match.week}`,
            start: new Date(match.date),
            end: new Date(match.date),
            allDay: true,
            id: match.id,
            bye: Boolean(match.bye)
        }))).sort((a, b) => (new Date(a.start)).getTime() - (new Date(b.start)).getTime()),
        [matches]
    );
    
    const onSelectMatch = useCallback(
        ({ id }) => {
            const match = (matches || []).find(match => match.id === id);
            setSelectedMatch(match);
            setUserSelected(true);
        },
        [setSelectedMatch, matches]
    );

    useEffect(() => {
        if (!userSelected && upcomingMatch) {
            const match = (matches || []).find(match => match.id === upcomingMatch.id);
            if (match) {
                setSelectedMatch(match)
            }
        }
    }, [upcomingMatch, userSelected, matches])

    useEffect(() => {
        if (!userToggled) {
            setToggle(isSmallScreen ? "DROPDOWN" : "CALENDAR")
        }
    }, [userToggled, isSmallScreen])

    const onToggle = useCallback(
        ({ target: { value } }) => {
            setToggle(value)
            setUserToggled(true);
        },
        [setToggle, setUserToggled]
    )
    
    return (
        <Schedule
            calendarEvents={calendarEvents}
            min={calendarEvents[0]}
            max={calendarEvents[calendarEvents.length-1]}
            selectedMatch={selectedMatch}
            onSelectMatch={onSelectMatch}
            toggle={toggle}
            onToggle={onToggle}
        />
    )
};

export default ScheduleContainer;