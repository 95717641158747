import { createTheme } from "@mui/material";

const { palette } = createTheme();

const THEMES = {
  'dark': {
    mode: 'dark',
    primary: palette.augmentColor({
      color: {
        main: "#362222"
      }
    }),
    secondary: palette.augmentColor({
      color: {
        main: "#FFF",
        contrastText: "#16213E"
      }
    }),
    spice: palette.augmentColor({
      color: {
        main: "#362222",
      }
    }),
    logo: palette.augmentColor({
      color: {
        main: "#850000",
      }
    }),
    tertiary: palette.augmentColor({
      color: {
        main: "#850000"
      }
    }),
    tertiaryGray: palette.augmentColor({
      color: {
        main: "#BB86FC"
      }
    }),
    header: palette.augmentColor({
      color: {
        main: "#FFF"
      }
    }),
  },
  'masters': {
    mode: 'light',
    primary: palette.augmentColor({
      color: {
        main: "#076652",
      }
    }),
    secondary: palette.augmentColor({
      color: {
        main: "#076652",
        contrastText: '#fff'
      }
    }),
    spice: palette.augmentColor({
      color: {
        main: "#fff700",
      }
    }),
    header: palette.augmentColor({
      color: {
        main: "#fff700"
      }
    }),
    logo: palette.augmentColor({
      color: {
        main: "#fff700",
      }
    }),
    background: {
      default: '#eae7dc',
      announcement: palette.augmentColor({
        color: {
          main: "#efebe9"
        }
      }),
      paper: "#efebe9",
    },
    tertiary: palette.augmentColor({
      color: {
        main: "#e85a4f",
      }
    }),
    tertiaryGray: palette.augmentColor({
      color: {
        main: "#8E8D8A"
      }
    }),
  },
  'light': {
    mode: 'light',
    primary: palette.augmentColor({
      color: {
        main: "#c9af67"
      }
    }),
    secondary: palette.augmentColor({
      color: {
        main: "#3742A2"
      }
    }),
    spice: palette.augmentColor({
      color: {
        main: "#c9af67",
      }
    }),
    header: palette.augmentColor({
      color: {
        main: "#3742A2"
      }
    }),
    logo: palette.augmentColor({
      color: {
        main: "#3742A2",
      }
    }),
    background: {
      default: '#eae7dc',
      announcement: palette.augmentColor({
        color: {
          main: "#efebe9"
        }
      }),
      paper: "#efebe9",
    },
    tertiary: palette.augmentColor({
      color: {
        main: "#e85a4f"
      }
    }),
    tertiaryGray: palette.augmentColor({
      color: {
        main: "#8E8D8A"
      }
    }),
  },
};

export const getPgalTheme = (mode = 'masters') => createTheme({
    palette: { ...THEMES[mode] },
    typography: {
      h6: {
        fontFamily: 'Crimson Text'
      },
      h5: {
        fontFamily: 'Crimson Text'
      },
      h4: {
        fontFamily: 'Crimson Text'
      },
      h3: {
        fontFamily: 'Crimson Text'
      },
      fontFamily: 'Roboto Condensed'
    },
    minWidth: '340px',
    paper: {
        borderRadius: 20,
        borderColor: "#000",
        padding: 50
    }
});

export const PGALThemes = Object.keys(THEMES).map(key => ({ [key]: key }))