import { useCallback, useState } from "react";
import useAppContext from "../../hooks/useAppContext";
import Teams from "./Teams";

const TeamsContainer = () => {
    const [selectedTeam, setSelectedTeam] = useState();
    const { season: { teams } } = useAppContext();

    const selectTeam = useCallback((value) => {
        const team = (teams || []).find(({ id }) => id === value);
        setSelectedTeam(team);
    }, [teams, setSelectedTeam]);

    return (
        <Teams
            teams={teams}
            selectedTeam={selectedTeam}
            onSelectTeam={selectTeam}
        />
    )
};

export default TeamsContainer;