import { Box, TextField } from "@mui/material";
import TeamName from "../../../Teams/TeamName";

const ScheduledWeek = ({
    week,
    teamMap
}) => (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box>
            <h3>Week {week.week}: {week.date}</h3>
            <ul>
                { 
                    week.matchups.map(matchup => (
                        <li>
                            <b>{matchup.time}</b> - {matchup.teams[0].teamId} vs {matchup.teams[1].teamId}
                            <br />
                            <TeamName team={teamMap[matchup.teams[0].teamId]} />
                            <br />
                            <TeamName team={teamMap[matchup.teams[1].teamId]} />
                            {/* <Button
                                color="tertiary"
                                onClick={() => onRemoveMatchup(matchup.id)}
                            >
                                Remove
                            </Button> */}
                        </li>
                    ))
                }
            </ul>
        </Box>
        <TextField
            value={JSON.stringify(week, null, 2)}
            multiline
            disabled
        />
    </Box>
);

export default ScheduledWeek;