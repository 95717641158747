import { Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import Panel from "../Panel/Panel";

const AdminHome = () => (
    <Authenticator
        hideSignUp={true}
    >
        {() => (
            <Panel
                title="Admin"
            >
                
            </Panel>
        )}
    </Authenticator>
);

export default AdminHome;