import { Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";

const MyDivider = styled(Divider)(({ theme }) => ({
    '&::before,::after': {
        borderTop: `thin solid ${theme.palette.primary.light}`,
    }
}));

const PGALDivider = ({
    text,
    variant,
}) => (
    <MyDivider
        light
    >
        <Typography
            variant={variant}
            sx={{
                color: 'header.dark',
                fontWeight: 'bold',
            }}
        >
            { text && text.toUpperCase() }
        </Typography>
    </MyDivider>
);

export default PGALDivider;