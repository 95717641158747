import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import useAppContext from "../../../../hooks/useAppContext";
import ScheduledWeek from "./ScheduledWeek"

const ScheduledWeekContainer = ({
    week,
    matchups,
    onScheduleMatchup,
    onRemoveMatchup,
}) => {
    const [selectedMatchup, setSelectedMatchup] = useState();
    const { teams } = useAppContext();

    const teamMap = useMemo(() => {
        return (teams || []).reduce((prev, curr) => ({
            ...prev,
            [curr.id]: curr,
        }), {})
    }, [teams])

    const scheduleMatchup = useCallback(() => {
        onScheduleMatchup(selectedMatchup, week.week)
        setSelectedMatchup("")
    }, [onScheduleMatchup, selectedMatchup, week])

    const filteredMatchups = useMemo(() => {
        const teamsInWeek = (week.matchups || []).reduce((prev, curr) => ([
            ...prev,
            curr.teams[0].id,
            curr.teams[1].id,
        ]), []);

        return (matchups || []).filter(matchup => !(teamsInWeek.includes(matchup.teams[0].id) || teamsInWeek.includes(matchup.teams[1].id)))
    },[week, matchups]);

    return (
        <ScheduledWeek
            week={week}
            matchups={filteredMatchups}
            selectedMatchup={selectedMatchup}
            onSelectMatchup={setSelectedMatchup}
            onScheduleMatchup={scheduleMatchup}
            onRemoveMatchup={onRemoveMatchup}
            teamMap={teamMap}
        />
    );
};

export default ScheduledWeekContainer;