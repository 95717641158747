import { useMemo } from "react";
import TeamScores from "./TeamScores";

const TeamsScoresContainer = ({
    season,
}) => {

    const sortedScores = useMemo(
        () => {
            if (season?.scores) {
                const sortedArray = [...season.scores];
                sortedArray.sort((a, b) => a.week - b.week)
                return sortedArray;
            }
            return undefined;
        },
        [season]
    );

    return (
        <TeamScores
            scores={sortedScores}
        />
    );
};

export default TeamsScoresContainer;