import { useMemo } from "react";
import { convertHandicapText, convertScoreText } from "../../utils/teamUtils";
import Score from "../Score";
import TeamName from "../Teams/TeamName";
import PlayoffLeaderboard from "./PlayoffLeaderboard";

const sortPlayoffRounds = (a, b) => a.round - b.round;

const reduceMatchups = matchups => (matchups || []).reduce((prev, curr) => ({
    ...prev,
    [curr.scores[0].teamId]: curr.scores[0], 
    [curr.scores[1].teamId]: curr.scores[1],
}), {})

const reducePlayoffs = playoffs => (playoffs || []).reduce((prev, curr) => ({
    ...prev,
    [curr.round]: reduceMatchups(curr.matchups),
}), {});

const HEADERS = [
    { 
        value: 'Pos',
        accessor: 'rank'
    },
    { 
        value: 'Team', 
        accessor: 'team',
        align: 'left',
        boldValue: true,
        Component: ({ children: value }) => <TeamName team={value} />
    },
    { 
        value: 'Adj. Total',
        accessor: 'adjustedTotal',
        Component: ({ children: value }) => <Score onConvertScore={convertScoreText} rationalized bold>{ value }</Score>,
        highlightRow: true,
    },
    { 
        value: 'Total', 
        accessor: 'total',
        Component: ({ children: value }) => <Score onConvertScore={convertScoreText} rationalized>{ value }</Score>
    },
    { 
        value: 'Handicap',
        accessor: 'handicap',
        Component: ({ children: value }) => <Score handicap onConvertScore={convertHandicapText} rationalized>{ value }</Score>
    },
    { 
        value: 'Bonus', 
        accessor: 'bonus'
    }
];

const getRoundKey = round => `R${round}`;

const PlayoffLeaderboardContainer = ({
    playoffs = [],
    playoffBoard = [],
}) => {
    const par = useMemo(() => {
        if (playoffs) {
            return playoffs.length * 36;
        }
        return 0;
    }, [playoffs]);

    playoffs.sort(sortPlayoffRounds)

    const playoffTeams = useMemo(
        () => {
            const reducedMatchups = reducePlayoffs(playoffs);
            return playoffBoard.map(team => ({
                ...team,
                adjustedTotal: team.adjustedScore,
                total: team.score,
                ...playoffs.reduce((prev, curr) => ({
                    ...prev,
                    [getRoundKey(curr.round)]: reducedMatchups[curr.round][team.id]?.score,
                }), {}),
            }));
        },
        [playoffBoard, par, playoffs],
    );

    const headers = useMemo(
        () => ([
            ...HEADERS,
            ...playoffs.map(({ round }) => ({
                value: getRoundKey(round), 
                accessor: getRoundKey(round),
                Component: ({ children: value }) => <Score>{ value }</Score>
            })),
        ]),
        [playoffs]
    );

    const rows = useMemo(
        () => playoffTeams.map(team => ({
            ...team,
            rank: team.rank,
            team: team,
            adjustedTotal: team.adjustedTotal,
            total: team.total,
            handicap: team.playoffHandicap,
            bonus: team.bonus,
        })),
        [playoffTeams, playoffs]
    );

    return (
        <PlayoffLeaderboard
            headers={headers}
            rows={rows}
        />
    );
};

export default PlayoffLeaderboardContainer;