import { Typography } from "@mui/material";

const trimName = name => {
    const splitName = name.split(' ');
    if (splitName.length === 2) {
        return `${name.charAt(0)}. ${splitName[1]}`
    }
    return name;
}

const TeamName = ({ team, variant = "span" }) => {
    if (!team || !team.players) {
        return null
    }

    if (!team.players || team.players.length === 0) {
        return `Team ${team.id.replace('t','')}`
    }



    return (
        <Typography
            variant={variant}
        >
            { trimName(team.players[0].name) }
            <br />
            { trimName(team.players[1].name) }
        </Typography>
    )
}

export default TeamName;