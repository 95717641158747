import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { Divider, Drawer } from "@mui/material";
import { useTheme } from "@emotion/react";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ThemeSwitch from "../../ThemeSwitch";

const AppBarDrawer = ({
    pages,
    adminPages, 
    active,
    onOpen,
    onClose,
    onAdminClick,
}) => {
    const theme = useTheme();
    const color = theme.palette.secondary.dark;
    return (
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={onOpen}
                color="inherit"
            >
                <MenuIcon
                    sx={{
                        bgColor: 'white'
                    }}
                />
            </IconButton>
            <Drawer
                anchor="left"
                open={active}
                onClose={onClose}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        height: "100%",
                    }}
                >
                    <Box>
                        {
                        pages.map(({ title, onClick, Icon }) => (
                            <MenuItem key={title} onClick={onClose}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={onClick}
                                    color="inherit"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirectio: 'row' 
                                        }}
                                    >
                                        <Icon
                                            sx={{
                                                marginRight: '8px',
                                                color: color,
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            component="span"
                                            textAlign="center"
                                            sx={{
                                                color,
                                            }}
                                        >
                                                {title}
                                        </Typography>
                                    </Box>
                                </IconButton>
                            </MenuItem>
                        ))}
                    </Box>
                    {
                        adminPages && adminPages.length > 0 ? (
                            <Box>
                                <Divider />
                                {
                                adminPages.map(({ title, onClick, Icon }) => (
                                    <MenuItem key={title} onClick={onClose}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={onClick}
                                            color="inherit"
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirectio: 'row' 
                                                }}
                                            >
                                                <Icon
                                                    sx={{
                                                        marginRight: '8px',
                                                    }}
                                                />
                                                <Typography
                                                    variant="h6"
                                                    component="span"
                                                    textAlign="center"
                                                >
                                                        {title}
                                                </Typography>
                                            </Box>
                                        </IconButton>
                                    </MenuItem>
                                ))}
                            </Box>
                        ) : (
                            <Box>
                                <MenuItem
                                    onClick={() => {
                                        onClose();
                                        onAdminClick();
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirectio: 'row' 
                                        }}
                                    >
                                        <AdminPanelSettingsIcon
                                            sx={{
                                                marginRight: '8px'
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            component="span"
                                            textAlign="center"
                                        >
                                                Sign-In
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </Box>
                        )
                    }
                </Box>
                <Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <ThemeSwitch />
                    </Box>
                </Box>
            </Drawer>
        </Box>
    )
};

export default AppBarDrawer;