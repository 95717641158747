import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PGALAppBar from './PGALAppBar';
import PGALHeader from './PGALHeader';
import useUser from '../../hooks/useUser'

const AppHeader = ({ pages, history }) => {
    const { user } = useUser();
    const pagesWithPush = useMemo(() => pages.filter(({ admin }) => !admin).map(page => ({
        ...page,
        onClick: () => history.push(page.path)
    })), [history, pages]);

    const adminPagesWithPush = useMemo(() => pages.filter(({ admin }) => admin && user).map(page => ({
        ...page,
        onClick: () => history.push(page.path)
    })), [history, pages, user]);

    const onAdminClick = useCallback(
        () => {
            history.push("/admin");
        },
        [history]
    );
    

    return (
        <>
            <PGALAppBar
                pages={pagesWithPush}
                adminPages={adminPagesWithPush}
                onAdminClick={onAdminClick}
            />
            <PGALHeader
                pages={pagesWithPush}
                adminPages={adminPagesWithPush}
                onAdminClick={onAdminClick}
            />
        </>
    );
}

export default withRouter(AppHeader);