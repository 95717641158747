import { Box, Typography, CircularProgress } from "@mui/material";

const SideEffect = ({
    loadingMessage,
}) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100% !important',
        }}
    >
        <Typography>
            { loadingMessage }
        </Typography>
        <CircularProgress color="inherit" />
    </Box>
);

export default SideEffect;