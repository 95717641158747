import { styled, Table, TableContainer, TableBody, TableRow, TableHead, TableCell, Paper, alpha } from "@mui/material";
import { getNestedProperty } from "../../../utils/object.utils";

const StyledTable = styled(TableContainer)(({ fontSize }) => ({
    fontSize,
    borderRadius: 0
}));

const StyleTableHead = styled(TableHead)(({ theme, color }) => ({
    background: theme.palette[color].dark,
    color: theme.palette[color].contrastText,
}));

const StyledHeaderCell = styled(TableCell)(({ theme, color, noBorder, fontSize }) => ({
    color: theme.palette[color].contrastText,
    padding: theme.spacing(.25),
    fontWeight: 'bold',
    fontSize,
    borderLeft: noBorder ? 0 : `1px ${theme.palette[color].light} solid`
}));

const StyledRow = styled(TableRow)(({ theme, ndx }) => ({
    background: alpha(theme.palette.secondary.contrastText, ndx % 2 === 0 ? 0.5 : 0),
}));

const SummaryRow = styled(TableRow)(({ theme, color }) => ({
    borderTop: `2px solid ${alpha(theme.palette[color].light, 0.5)}`,
    background: alpha(theme.palette[color].light, 0.15),
}));

const StyledCell = styled(TableCell)(({ theme, bold, highlight, fontSize }) => ({
    padding: theme.spacing(.25),
    backgroundColor: highlight ? `${theme.palette.mode === 'dark' ? theme.palette.tertiary.main : theme.palette.secondary.main}20` : 'none',
    fontWeight: bold ? 'bold' : 'none',
    fontSize,
}));

const PGALTable = ({
    headers,
    rows,
    summaryRow,
    fontSize = '1.0 em',
    color = 'primary',
}) => (
    <StyledTable
        fontSize={fontSize}
        component={Paper}
    >
        <Table aria-label="simple table">
            <StyleTableHead color={color} >
                <TableRow>
                    { headers && headers.map((header, ndx) => (
                        <StyledHeaderCell
                            color={color}
                            noBorder={ndx === 0}
                            fontSize={fontSize}
                            align={header.align || 'center'}
                        >
                                { header.value.toUpperCase() }
                        </StyledHeaderCell>
                    ))}
                </TableRow>
            </StyleTableHead>
            <TableBody>
            {rows.map((row, ndx) => (
                <StyledRow
                    ndx={ndx}
                    key={ndx}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    { headers.map(column => (
                        <StyledCell 
                            align={column.align || 'center'}
                            highlight={column.highlightRow}
                            fontSize={fontSize}
                            bold={column.boldValue}
                        >
                            {
                                column.Component 
                                    ? <column.Component row={row} >{getNestedProperty(row, column.accessor)}</column.Component>
                                    : getNestedProperty(row, column.accessor)
                            }
                        </StyledCell>
                    ))}
                </StyledRow>
            ))}
            { summaryRow && (
                <SummaryRow
                    key={'summary'}
                    color={color}
                >
                    { headers.map(column => (
                        <StyledCell 
                            align={column.align || 'center'}
                            highlight={column.highlightRow}
                            fontSize={fontSize}
                            bold={column.boldValue}
                        >
                            {
                                column.Component 
                                    ? <column.Component>{getNestedProperty(summaryRow, column.accessor)}</column.Component>
                                    : getNestedProperty(summaryRow, column.accessor)
                            }
                        </StyledCell>
                    ))}
                </SummaryRow>
            )}
            </TableBody>
        </Table>
    </StyledTable>
);

export default PGALTable;