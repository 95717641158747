import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import Announcements from '../Announcement/Announcements';
import HomeScheduleOverview from '../HomeScheduleOverview';
import PlayoffLeaderboard from '../PlayoffLeaderboard';
import StandingsPreview from '../StandingsPreview';
import Foxford_18 from '../../images/foxford_18.jpg';
import Foxford_Overview from '../../images/foxford_overview.jpg'
import MainPage from '../../layouts/MainPage';
import Leaderboard from '../Leaderboard';

const StyledContent = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        backgroundImage: `url(${Foxford_18})`,
    },
    [theme.breakpoints.up('md')]: {
        backgroundImage: `url(${Foxford_Overview})`,
        backgroundPosition: 'center'
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '280px',
    maxHeight: '280px',
    padding: `${theme.spacing(6)}px`,
    display: 'flex',
    flexGrow: 1,
    flexDirection: "column",
    color: 'white',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    width: '100%',
}));

const Home = ({
    season,
}) => (
    <MainPage>
        <MainPage.Header>
            <StyledContent>
                <Typography 
                    component="h1"
                    variant="h5"
                    color="inherit"
                    gutterBottom
                    sx={{
                        background: 'rgba(0, 0, 0, 0.6)',
                        paddingLeft: '8px',
                        textShadow: '1px 1px black'
                    }}
                >
                    PGAL {season?.id} SEASON
                </Typography>
                <Typography 
                    component="p"
                    variant="p"
                    color="inherit"
                    sx={{
                        background: 'rgba(0, 0, 0, 0.6)',
                        paddingLeft: '8px',
                        textShadow: '1px 1px black',
                    }}
                >
                    Checkout the League Info page for the format and rules for the {season?.id} season
                </Typography>
            </StyledContent>
        </MainPage.Header>
        <MainPage.BodyLeft>
            <Announcements />
        </MainPage.BodyLeft>
        <MainPage.BodyRight>
            <HomeScheduleOverview />
        </MainPage.BodyRight>
        <MainPage.Body>
            <Leaderboard
                season={season}
            />
        </MainPage.Body>
    </MainPage>
)

export default Home;