import { useMemo } from "react";
import { useEffect, useState } from "react";
import { fetchTeam } from "../../../api/pgalApis";
import InlineLoader from "../../common/InlineLoader";
import Team from "./Team";

const TeamContainer = ({
    team
}) => {
    const [selectedHoles, setSelectedHoles] = useState('overall');
    const [enrichedTeam, setEnrichedTeam] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(
        () => {
            const fetchData = async () => {
                setLoading(true);
                const data = await fetchTeam(team.id, 'true');
                setEnrichedTeam(data)
                setLoading(false);
            }
            if (team) {
                fetchData();
            }
        },
        [team]
    );

    const seasonRows = useMemo(
        () => {
            const rows = Object.keys(enrichedTeam?.stats || {}).filter(key => key !== 'overall').map(key => ({
                ...enrichedTeam.stats[key],
                season: key,
            }));

            rows.sort((a, b) => b.season-a.season);

            if (selectedHoles === 'overall') {
                return rows;
            }

            return rows.map(row => ({
                ...row,
                ...row[selectedHoles],
            }));
        },
        [enrichedTeam, selectedHoles]
    );

    const summaryRow = useMemo(
        () => {
            const row = {
            ...enrichedTeam?.stats?.overall || {}, 
            season: 'Overall',
            };

            if (selectedHoles === 'overall') {
                return row;
            }

            return {
                ...row,
                ...row[selectedHoles],
            };
        },
        [enrichedTeam, selectedHoles]
    );

    return (
        <>
            <InlineLoader
                loading={loading || !enrichedTeam}
                text="Loading Team..."
            >
                <Team
                    team={enrichedTeam}
                    seasonRows={seasonRows}
                    summaryRow={summaryRow}
                    selectedHoles={selectedHoles}
                    onSelectHoles={setSelectedHoles}
                />
            </InlineLoader>
        </>
    )
};

export default TeamContainer;