import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PGALDivider from "../../common/PGALDivider";
import Panel from "../../Panel";
import PlayoffLeaderboard from "../../PlayoffLeaderboard";
import StandingsPreview from "../../StandingsPreview";
import ManageHandicap from "./ManageHandicap";

const ManageSeason = ({
    selectedSeason,
    onSelectSeason,
    form,
    onFormChange,
    metadataOptions,
    handicapMetadata,
    onPreview,
    season,
    onSave,
}) => (
    <Panel
        title="Manage Season"
    >
        <Box>
            <FormControl fullWidth>
                <InputLabel id="seasonLabel">Season</InputLabel>
                <Select
                    labelId="seasonLabel"
                    id="seasonDropdown"
                    label="Season"
                    value={selectedSeason || ''}
                    onChange={({ target: { value }}) => {
                        onSelectSeason(value) 
                    }}
                >
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                </Select>
            </FormControl>
        </Box>
        { selectedSeason && metadataOptions && (
            <>
                <Divider sx={{ marginTop: '16px', marginBottom: '8px' }} />
                <ManageHandicap
                    form={form}
                    onFormChange={onFormChange}
                    metadataOptions={metadataOptions}
                    handicapMetadata={handicapMetadata}
                    onPreview={onPreview}
                    onSave={onSave}
                />
            </>
        )}
        {
            season && (
                <>
                    <PGALDivider
                        text="Playoffs"
                        variant="h4"
                    />
                    <PlayoffLeaderboard
                        playoffs={season.playoffs}
                        playoffBoard={season.playoffBoard}
                    />
                    <br />
                    <PGALDivider
                        text="Standings"
                        variant="h4"
                    />
                    <StandingsPreview
                        teams={season.teams}
                        hideStreak
                    />
                </>
            )
        }
    </Panel>
);

export default ManageSeason;