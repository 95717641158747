import { Container } from "@mui/material";
import { Box, styled } from "@mui/system";
import PGALDivider from "../common/PGALDivider";

const MyContainer = styled(Container)(({ theme, removeContentPadding }) => ({
    background: theme.palette.secondary.contrastText,
    marginBottom: theme.spacing(1),
    borderTop: `4px solid ${theme.palette.primary.light}`,
    padding: `${removeContentPadding ? 0 : theme.spacing(1)}!important`,
    width: '100%',
}));

const MyHeader = styled(Box)(({ theme, removeContentPadding }) => ({
    padding: `${!removeContentPadding ? 0 : theme.spacing(1)}!important`,
}));

const Panel = ({
    title,
    children,
    variant = "h5",
    removeContentPadding
}) => (
    <MyContainer
        removeContentPadding={removeContentPadding}
    >
        <MyHeader
            removeContentPadding={removeContentPadding}
        >
            <PGALDivider
                variant={variant}
                text={title}
            />
        </MyHeader>
        <Box>
            { children }
        </Box>
    </MyContainer>
);


export default Panel;