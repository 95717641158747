import { Box, FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import Panel from "../../Panel";
import ManageMatchup from './ManageMatchup';

const ManageMatchups = ({
    matches,
    teams,
    selectedMatch,
    onSelectMatch,
}) => (
    <Panel
        title="Manage Matchups"
    >
        <Box>
            <FormControl fullWidth>
                <InputLabel id="weekLabel">Week</InputLabel>
                <Select
                    labelId="weekLabel"
                    id="weekDropdown"
                    label="Week"
                    value={selectedMatch?.id || ''}
                    onChange={({ target: { value }}) => {
                        onSelectMatch(value) 
                    }}
                >
                    { (matches || []).map(match => <MenuItem value={match.id}>{ `Week ${match.week }${ match.playOff ? ' (Playoffs)' : '' }` }</MenuItem>) }
                </Select>
            </FormControl>
        </Box>
        {
            selectedMatch && (
                <>
                    <Divider sx={{ marginTop: '16px', marginBottom: '8px' }} />
                    <ManageMatchup
                        teams={teams}
                        match={selectedMatch}
                    />
                </>
            )
        }
    </Panel>
);

export default ManageMatchups;