import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import ReactMarkdown from 'markdown-to-jsx';

const StyledList = styled('li')(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const options = {
    overrides: {
        h1: { component: props => <Typography gutterBottom variant="h6" {...props} /> },
        h2: { component: props => <Typography gutterBottom variant="h6" {...props} /> },
        h3: { component: props => <Typography gutterBottom variant="subtitle1" {...props} /> },
        h4: { component: props => <Typography gutterBottom variant="caption" paragraph {...props} /> },
        p: { component: props => <Typography {...props} /> },
        li: {
            component: (props) => (
                <StyledList>
                    <Typography component="span" {...props} />
                </StyledList>
            ),
        },
    },
};

const PGALMarkdown = ({ content }) => {
    if (!content) {
        return null;
    }
    return (
        <ReactMarkdown
            options={options}
        >
            { content }
        </ReactMarkdown>
)};

export default PGALMarkdown;