import React from 'react';
import { Grid, styled, useMediaQuery, useTheme } from "@mui/material";

const HiddenMediumSection = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'none'
    },
}));

const HiddenSmallSection = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none'
    },
}));


const MainPage = ({
    children
}) => {
    const theme = useTheme();
    const isVertical  = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid 
            container 
            spacing={ isVertical ? 0 : 1 }
            sx={{
                width: '100%',
            }}
        >
            <Grid item sm={12}>
                { React.Children.map(children, (child) => child.type.displayName === 'Header' ? child : null) }
            </Grid>
            <Grid
                item
                sm={12}
                md={7} 
                sx={{
                    width: '100%',
                }}
            >
                { React.Children.map(children, (child) => child.type.displayName === 'BodyLeft' ? child : null) }
                <HiddenSmallSection>
                    { React.Children.map(children, (child) => child.type.displayName === 'Body' ? child : null) }
                </HiddenSmallSection>
            </Grid>
            <Grid item sm={12} md={5} sx={{ width: '100%' }}> 
                { React.Children.map(children, (child) => child.type.displayName === 'BodyRight' ? child : null) }
                <HiddenMediumSection>
                    { React.Children.map(children, (child) => child.type.displayName === 'Body' ? child : null) }
                </HiddenMediumSection>
            </Grid>
        </Grid>
    );
}

const Header = ({ children }) => <>{ children }</>
MainPage.Header = Header;
MainPage.Header.displayName = 'Header';

const BodyLeft = ({ children }) => <>{ children }</>;
MainPage.BodyLeft = BodyLeft;
MainPage.BodyLeft.displayName = 'BodyLeft';

const BodyRight = ({ children }) => <>{ children }</>;
MainPage.BodyRight = BodyRight;
MainPage.BodyRight.displayName = 'BodyRight';

const Body = ({ children }) => <>{ children }</>;
MainPage.Body = Body;
MainPage.Body.displayName = 'Body';

export default MainPage;