import PGALDropdown from '../PGALDropdown';
import Panel from '../Panel';
import PlayoffLeaderboard from '../PlayoffLeaderboard';
import StandingsPreview from '../StandingsPreview';
import { Grid } from '@mui/material';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import TeamName from '../Teams/TeamName';
import Leaderboard from '../Leaderboard';

const Place = ({
    color,
    team,
}) => (
    <Grid
        container
        justifyContent="center"
        alignItems="center"
    >
        <Grid
            item
            xs={3}
            md={4}
            style={{textAlign: "right"}}
        >
            <TrophyIcon
                color={color}
                sx={{
                    fontSize: '48px',
                }}
            />
        </Grid>
        <Grid
            item
            xs={6}
            md={4}
            style={{textAlign: "center"}}
        >
            <TeamName
                team={team}
            />
        </Grid>
        <Grid
            item
            xs={3}
            md={4}
            style={{textAlign: "left"}}
        >
            <TrophyIcon
                color={color}
                sx={{
                    fontSize: '48px',
                }}
            />
        </Grid>
    </Grid>
)

const PreviousSeasons = ({
    season,
    seasonsOptions,
    onSelectSeason,
}) => (
    <Panel
        title="Previous Seasons"
    >
        <PGALDropdown
            id="prevSeasonDropdown"
            label="Previous Seasons"
            value={season?.id}
            onChange={onSelectSeason}
            menuItems={seasonsOptions}
            fullWidth
        />
        { season && (
            <>   
                <br />
                <br />
                <Leaderboard
                    season={season}
                />
            </>
        )}
    </Panel>
);

export default PreviousSeasons;